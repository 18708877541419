import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import { NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchFestival, updateFestival, saveFestival } from '../../actions';
import { Button, FormRow, Map, FestivalHeader, FileUpload, DateFormWidget, Loading } from '../controls';

const defaultDateObject = { startTime: null, endTime: null, startTimeMS: null, endTimeMS: null };

class FestivalFormComponent extends React.Component {
  componentDidMount() {
    const { match, actions } = this.props;

    if (!match) {
      actions.updateFestival({});
      return;
    }

    if (!match.params || isNaN(match.params.id)) {
      actions.updateFestival({});
    } else {
      actions.fetchFestival(match.params.id);
    }
  }

  handleDateChange = (dates) => {
    const { festival, actions } = this.props;
    actions.updateFestival({ ...festival, dates });
  }

  mapCoordinatesChanged = (location) => {
    const { festival, actions } = this.props;
    actions.updateFestival({ ...festival, location: { ...location, radius: 1000 } });
  }

  submit = (values, { setSubmitting }) => {
    const { name, description, links, locationName } = values;
    const { festival, actions } = this.props;
    festival.location = { ...festival.location, locationName };
    const updatedFestival = { ...festival, name, description, links };

    delete updatedFestival.startDate;

    actions.saveFestival(updatedFestival)
      .then(() => NotificationManager.success('Festival details saved', '', 3000))
      .catch(() => NotificationManager.error('Could not save festival details', '', 3000));
  }

  onFileUpload = (fileInfo) => {
    const { festival, actions } = this.props;
    const { filename, url } = fileInfo;
    actions.updateFestival({ ...festival, photoURL: url, photo: filename });
  }

  onFileDelete = () => {
    const { festival, actions } = this.props;
    actions.updateFestival({ ...festival, photoURL: null, photo: null });
  }

  save = () => {
    this.form.submitForm();
  }

  validateDates = (dates) => {
    const validDates = dates.filter(dateObj => dateObj.startTimeMS && dateObj.endTimeMS);
    return validDates && validDates.length === dates.length;
  }

  validateForm = (values) => {
    const { festival } = this.props;
    const errors = {};

    if (!values.name) {
      errors.name = 'Name is required';
    }

    if (!values.description) {
      errors.description = 'Description is required';
    }

    // Location and dates are updated through redux state so grab them off the festival
    if (!festival.dates || !this.validateDates(festival.dates)) {
      errors.dates = 'At least one date is required.';
    }

    if (!festival.location) {
      errors.location = 'Location is required.';
    }

    return errors;
  }

  render() {
    const { festival, loading } = this.props;
    const dates = festival.dates || [defaultDateObject];
    const defaultLinks = {
      instagram: '',
      twitter: '',
      facebook: '',
      web: '',
    };
    const defaultValues = {
      name: festival.name || '',
      description: festival.description || '',
      locationName: festival.location ? festival.location.locationName : '',
      links: festival.links || defaultLinks,
      dates
    };
    var fileList = [];

    if (festival.photoURL) {
      fileList.push({
        uid: '-1',
        name: festival.photo,
        url: festival.photoURL,
        status: 'done'
      });
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <div>
        {(festival && festival.name) &&
          <FestivalHeader title={festival ? festival.name : 'Add New Event'} onSave={this.save} showExit={true} />
        }
        <Formik
          ref={node => this.form = node}
          enableReinitialize
          initialValues={defaultValues}
          onSubmit={this.submit}
          validate={this.validateForm}
        >
          {
            ({ errors, touched }) => (
              <Form>
                <FormRow label="Festival Image">
                  <FileUpload fileList={fileList} onFileUpload={this.onFileUpload} onFileDelete={this.onFileDelete} />
                </FormRow>
                <FormRow label="Event Name" errorName="name">
                  <Field type="text" name="name" placeholder="Type an event name here..." />
                </FormRow>
                <DateFormWidget label="Event Dates & Times" dates={dates} handleDateChange={this.handleDateChange} allowMultiple={true} />
                <FormRow label="Location">
                  <Field type="text" name="locationName" placeholder="Type a Location Description Here" />
                </FormRow>
                <FormRow errorName="location">
                  <Map location={festival.location} handleClick={this.mapCoordinatesChanged} />
                </FormRow>
                <FormRow label="General Information" errorName="description">
                  <Field component="textarea" name="description" placeholder="Festival Information..." />
                </FormRow>
                <FormRow label={<FontAwesomeIcon icon={['fal', 'globe']} />}>
                  <Field type="text" name="links.web" placeholder="Website" />
                </FormRow>
                <FormRow label={<FontAwesomeIcon icon={['fab', 'facebook']} />}>
                  <Field type="text" name="links.facebook" placeholder="Facebook" />
                </FormRow>
                <FormRow label={<FontAwesomeIcon icon={['fab', 'twitter']} />}>
                  <Field type="text" name="links.twitter" placeholder="Twitter" />
                </FormRow>
                <FormRow label={<FontAwesomeIcon icon={['fab', 'instagram']} />}>
                  <Field type="text" name="links.instagram" placeholder="Instagram" />
                </FormRow>
                <Button type="button" onClick={this.save} style={{ float: 'right', marginTop: '1rem' }}mode="dark">Save Festival</Button>
              </Form>
            )
          }
        </Formik>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  festival: state.festivalStore.festival,
  loading: state.festivalStore.loading
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchFestival,
    updateFestival,
    saveFestival,
  }, dispatch)
});


export const FestivalForm = connect(mapStateToProps, mapDispatchToProps)(FestivalFormComponent);
