import React from 'react';
import { connect } from 'react-redux';
import axiosClient from '../../actions/axios-client';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { NotificationManager } from 'react-notifications';
import { Button, FestivalHeader, FormRow } from '../controls';

const PushNotificationSchema = Yup.object({
  text: Yup.string().required('Message is required')
});

class PushNotifications extends React.Component {
  state = {
    sending: false,
  };

  handleSubmit = (values, { resetForm }) => {
    const { festival } = this.props;
    axiosClient.post(`/admin/message/${festival.festivalId}`, values)
      .then(() => {
        NotificationManager.success('Notification sent', '', 3000)
        resetForm({ title: '', text: '' });
      })
      .catch(() => {
        NotificationManager.error('Could not send notification', '', 3000);
      });
  }

  render() {
    const defaultValues = {
      text: '',
    };

    return (
      <>
        <FestivalHeader title="Push Notification" />
        <Formik
          ref={node => this.form = node}
          enableReinitialize
          initialValues={defaultValues}
          onSubmit={this.handleSubmit}
          validationSchema={PushNotificationSchema}
        >
          {
            ({ errors, touched }) => (
              <Form>
                <FormRow label="Message" errorName="text">
                  <Field component="textarea" name="text" placeholder="Message" />
                </FormRow>
                <Button style={{ float: 'right' }}>Send Notification</Button>
              </Form>
            )
          }
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  festival: state.festivalStore.festival,
});

export default connect(mapStateToProps)(PushNotifications);
