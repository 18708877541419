import axiosClient from './axios-client';

export const fetchServices = (festivalId) => ({
  type: 'FETCH_SERVICES',
  payload: axiosClient.get(`/admin/services/${festivalId}`)
});

export const fetchServiceTypes = () => ({
  type: 'FETCH_SERVICE_TYPES',
  payload: axiosClient.get('/admin/service-types')
});

export const saveService = (service, festivalId) => ({
  type: 'SAVE_SERVICE',
  payload: axiosClient.post(`/admin/service/${festivalId}`, service)
});

export const removeService = (serviceId) => ({
  type: 'REMOVE_SERVICE',
  payload: axiosClient.delete(`/admin/service/${serviceId}`)
});