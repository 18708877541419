import axiosClient from './axios-client';

export const login = (credentials) => ({
    type: 'LOGIN',
    payload: axiosClient.post(`/admin/login`, credentials)
});

export const logout = () => ({
    type: 'LOGOUT',
    payload: axiosClient.get(`/admin/logout`)
});

export const checkLoginStatus = () => ({
    type: 'CHECK_LOGIN_STATUS',
    payload: axiosClient.get(`/admin/account`)
});
