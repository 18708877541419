import axiosClient from './axios-client';

export const fetchAttractions = (festivalId) => ({
  type: 'FETCH',
  property: 'attractions',
  payload: axiosClient.get(`/admin/attractions/${festivalId}`)
});

export const saveAttraction = (festivalId, attraction) => ({
  type: 'SAVE',
  property: 'attraction',
  payload: axiosClient.post(`/admin/attraction/${festivalId}`, attraction)
});

export const removeAttraction = (attractionId) => ({
  type: 'REMOVE',
  property: 'attraction',
  payload: axiosClient.delete(`/admin/attraction/${attractionId}`)
});
