import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications'
import { logout } from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const Header = styled.header`
  height: 80px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  line-height: 80px;

  display: flex;
  justify-content: space-between;

  h1 {
    color: ${props => props.theme.skyBlue};
    margin: 0;
    padding-left: 1rem;
    font-family: Phosphate-Solid;
    margin: 0 auto;
    margin-bottom: 30px;
    background-image: linear-gradient(105deg, #49c6e9, #2d98cc);
    font-family: Phosphate-Inline;
    font-size: 40px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 80px;
    letter-spacing: normal;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  button{
    width: 150px;
    height: 40px;
    border-radius: 20px;
    background-image: linear-gradient(105deg, #49c6e9, #2d98cc);
    line-height: 0px;
    margin-top: 20px;
    margin-right: 16px;
  }

  span{
    width: 52px;
    height: 19px;
    font-family: Phosphate-Solid;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
`;

const mapStateToProps = (state) => ({
  //no state needed to listen to here
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ logout }, dispatch)
});

class HeaderComponent extends React.Component {
  componentDidMount(){

  }
  logout = () => {
    this.props.actions.logout();
    NotificationManager.success('Logout successful', '', 3000);
  };

  render(){
    return(
      <>
        {this.props.withHeader && (
          <Header>
            <Link to="/">
              <h1>Festyland</h1>
            </Link>
            <button onClick={this.logout}>
              <span>Log Out</span>
            </button>
          </Header>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
