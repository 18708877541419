import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationManager } from 'react-notifications';
import { fetchPerformers, fetchPerformerTypes, savePerformer } from '../../actions';
import { FestivalHeader, Loading } from '../controls';
import FilterableTable from '../table';
import Portal from '../modal/Portal';
import { PerformerForm } from './';

class PerformersComponent extends React.Component {
  state = {
    showModal: false,
    performer: {},
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.fetchPerformers();
    actions.fetchPerformerTypes();
  }

  buildColumnDefinitions = () => {
    var columnDefs = {};

    columnDefs.performerName = {
      header: 'Performer',
      filterable: false,
      sortable: true,
      showViewLink: false,
      handleClick: true
    };
    columnDefs.type = {
      header: 'Type',
      filterable: false,
      sortable: false
    };
    columnDefs.hometown = {
      header: 'Hometown',
      filterable: false,
      sortable: false
    };

    return columnDefs;
  }

  onFileUpload = (fileInfo) => {
    const { performer } = this.state;
    const { url, filename } = fileInfo;
    this.setState({ performer: { ...performer, photoURL: url, performerPhoto: filename } });
  }

  onFileDelete = () => {
    const { performer } = this.state;
    this.setState({ performer: { ...performer, photoURL: null, performerPhoto: null } });
  }

  savePerformer = (newValues) => {
    const { performer } = this.state;
    const { actions: { savePerformer, fetchPerformers } } = this.props;
    const updatedPerformer = { ...performer, ...newValues };

    savePerformer(updatedPerformer)
      .then(() => {
        this.closeModal();
        fetchPerformers();
        NotificationManager.success('Performer saved', '', 3000);
      })
      .catch(() => {
        NotificationManager.error('Performer could not be saved', '', 3000);
      });
  }

  openPerformerModal = (selectedPerformer) => {
    const { performers } = this.props;
    const performer = performers.find(p => p.performerId === selectedPerformer.performerId) || {};
    this.setState({ performer, showModal: true });
  }

  closeModal = () => this.setState({ showModal: false });

  render() {
    const { performers, loading, performerTypes } = this.props;
    const { performer, showModal } = this.state;

    return (
      <>
        <FestivalHeader title="Performers" showAdd={true} addTitle="Add Performer" addAction={this.openPerformerModal} />
        {loading ? (
          <Loading />
        ) : (
          <FilterableTable
            data={performers}
            tableTitle=''
            columns={this.buildColumnDefinitions()}
            includeFilter={false}
            viewLinkBuilder={this.buildViewLink}
            editHandler={this.openPerformerModal}
            toggleModal={() => this.setState({ showModal: true, performer: {} })}
          />
        )}

        <Portal
          header={performer ? performer.performerName || 'Add New Performer' : 'Add New Stage'}
          open={showModal}
          onClose={this.closeModal}
        >
          <PerformerForm
            performer={performer}
            performerTypes={performerTypes}
            handleSave={this.savePerformer}
            onFileUpload={this.onFileUpload}
            onFileDelete={this.onFileDelete}
          />
        </Portal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  performers: state.performerStore.performers,
  performerTypes: state.performerStore.types,
  loading: state.performerStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchPerformers,
    fetchPerformerTypes,
    savePerformer,
  }, dispatch)
});

export const Performers = connect(mapStateToProps, mapDispatchToProps)(PerformersComponent);
