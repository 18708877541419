const initialState = {
    currentUser: {},
    isAuthenticated: false,
    loading: false,
    error: false,
};

export default (state = initialState, action = {}) => {
    switch(action.type){
        case 'LOGIN_PENDING':
            return {...state, loading: true, error: false};
        case 'LOGIN_FULFILLED':
            return {...state, loading: false, error: false, isAuthenticated: true, currentUser: action.data};
        case 'LOGIN_REJECTED':
            return {...state, loading: false, error: true, isAuthenticated: false};
        case 'LOGOUT_PENDING':
          return {...state, loading: true, error: false};
        case 'LOGOUT_FULFILLED':
            return {...state, loading: false, error: false, isAuthenticated: false, currentUser: {}};
        case 'LOGOUT_REJECTED':
          return {...state, loading: false, error: true, isAuthenticated: false};
        case 'CHECK_LOGIN_STATUS_PENDING':
            return {...state, loading: true, error: false};
        case 'CHECK_LOGIN_STATUS_FULFILLED':
            return {...state, loading: false, error: false, isAuthenticated: true, currentUser: action.data};
        case 'CHECK_LOGIN_STATUS_REJECTED':
            return {...state, loading: false, error: true, isAuthenticated: false, currentUser: {}};
        default:
            return state;
    }
};
