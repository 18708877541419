import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { Button, FormRow, Map, DateFormWidget, FileUpload } from '../controls';

const Wrapper = styled.div`
  padding: 1rem 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: middle;
`;

const LeftWrapper = styled.div`
  padding: 1rem;
  width: 75%;
`;

const RightWrapper = styled.div`
  padding: 1rem;
  width: 25%;
`;

const StyledFileUpload = styled(FileUpload)`
  width: 100%;
`;

const StageSchema = Yup.object().shape({
  stageName: Yup.string().required('Stage name is required')
});

const defaultDateObject = { startTime: null, endTime: null, startTimeMS: null, endTimeMS: null };

class StageFormComponent extends React.Component {
  render() {
    const { stage, onStageSave, onDateChange, onLocationChange, onFileUpload, onFileDelete } = this.props;
    const dates = stage ? stage.times || [defaultDateObject] : [defaultDateObject];
    const defaultValues = {
      stageName: stage ? stage.stageName || '' : '',
      stageDescription: stage ? stage.stageDescription || '' : ''
    };
    var fileList = [];

    if (stage.photoURL) {
      fileList.push({
        uid: '-1',
        name: stage.photo,
        url: stage.photoURL,
        status: 'done'
      });
    }

    return (
      <Wrapper>
        <Formik
          ref={node => this.form = node}
          enableReinitialize
          initialValues={defaultValues}
          onSubmit={onStageSave}
          validationSchema={StageSchema}
        >
          {
            ({ errors, touched }) => (
              <Form>
                <InfoWrapper>
                  <LeftWrapper>
                    <FormRow label="Stage Name" errorName="stageName">
                      <Field type="text" name="stageName" placeholder="Stage Name" />
                    </FormRow>
                    <DateFormWidget label="Dates & Times" dates={dates} handleDateChange={onDateChange} allowMultiple={true} />
                  </LeftWrapper>
                  <RightWrapper>
                    <FormRow>
                      <StyledFileUpload fileList={fileList} onFileUpload={onFileUpload} onFileDelete={onFileDelete} />
                    </FormRow>
                  </RightWrapper>
                </InfoWrapper>
                <FormRow>
                  <Map location={stage.location} handleClick={onLocationChange} />
                </FormRow>
                <FormRow label="General Information">
                    <Field component="textarea" name="stageDescription" placeholder="Stage Information..." />
                </FormRow>
                <Button style={{ float: 'right', marginTop: '1rem' }} mode="dark">Save Stage</Button>
              </Form>
            )
          }
        </Formik>
      </Wrapper>
    )
  }
}

export const StageForm = StageFormComponent;
