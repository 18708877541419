import isPromise from 'is-promise';
// import { logout } from '../actions';

const apiMiddleware = ({ dispatch, getState }) => next => action => {
const { type, payload, property } = action;

  if (!isPromise(payload)) {
    return next(action);
  }

  dispatch({ type: `${type}_PENDING` });
  return payload
  .then(response => {
    dispatch({ type: `${type}_FULFILLED`, data: response.data, property });
  })
  .catch(error => {
    if (action.type !== 'LOGOUT' && error.response.status === 401) {
      dispatch({ type: 'LOGOUT' });
      throw error;
    }

    dispatch({ type: `${type}_REJECTED`, error: error.response.data || 'ERROR', property });
  });
}

export default apiMiddleware;
