import { updateStateWithLoading, updateStateWithValue, updateStateWithError } from './';

const initialState = {
  sponsors: [],
  objectSponsors: [],
  sponsor: {},
  loading: false,
  error: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_SPONSORS_PENDING':
    case 'FETCH_SPONSORS_OBJECT_PENDING':
    case 'SAVE_SPONSOR_OBJECT_PENDING':
    case 'SAVE_SPONSOR_PENDING':
    case 'REMOVE_SPONSOR_PENDING':
    case 'REMOVE_SPONSOR_OBJECT_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_SPONSORS_FULFILLED':
    case 'SAVE_SPONSOR_FULFILLED':
    case 'SAVE_SPONSOR_OBJECT_FULFILLED':
    case 'FETCH_SPONSORS_OBJECT_FULFILLED':
      return updateStateWithValue(action.property, action.data, state);

    case 'REMOVE_FULFILLED':
    case 'REMOVE_SPONSOR_OBJECT_FULFILLED':
      return updateStateWithValue(action.property, {});

    case 'FETCH_SPONSORS_REJECTED':
    case 'FETCH_SPONSORS_OBJECT_REJECTED':
    case 'SAVE_SPONSOR_REJECTED':
    case 'SAVE_SPONSOR_OBJECT_REJECTED':
    case 'REMOVE_SPONSOR_REJECTED':
    case 'REMOVE_SPONSOR_OBJECT_REJECTED':
      return updateStateWithError(action.error, state);

    default:
      return state;
  }
}
