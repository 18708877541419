import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { Attractions } from '../components/attractions';
import Login from '../components/login/Login';
import Home from '../components/home';
import { FestivalForm } from '../components/festivals';
import { Performers } from '../components/performers';
import { Stages, StageFormContainer } from '../components/stages';
import { Services } from '../components/services';
import { Sponsors } from '../components/sponsors';
import { FestivalVendors } from '../components/vendors';
import { FAQS } from '../components/faq';
import PushNotifications from '../components/push-notifications';

export default (props) => (
  <Switch>
    <Route exact path="/login" component={Login}/>
    <ProtectedRoute exact path="/" component={Home} />
    <ProtectedRoute exact path="/stages" component={Stages} />
    <ProtectedRoute path="/stage/:id" component={StageFormContainer} />
    <ProtectedRoute path="/festival/:id" component={FestivalForm} />
    <ProtectedRoute path="/performers" component={Performers} />
    <ProtectedRoute path="/vendors" component={FestivalVendors} />
    <ProtectedRoute path="/services" component={Services} />
    <ProtectedRoute path="/sponsors" component={Sponsors} />
    <ProtectedRoute path="/faq" component={FAQS} />
    <ProtectedRoute path="/attractions" component={Attractions} />
    <ProtectedRoute path="/push-notification" component={PushNotifications} />
  </Switch>
)
