import { updateStateWithLoading, updateStateWithValue, updateStateWithError } from './';

const initialState = {
  faqs: [],
  faq: {},
  loading: false,
  error: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_FAQS_PENDING':
    case 'REMOVE_FAQ_PENDING':
    case 'SAVE_FAQ_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_FAQS_FULFILLED':
      return updateStateWithValue(action.property, action.data, state);

    case 'REMOVE_FAQ_FULFILLED':
      return updateStateWithValue(action.property, {}, state);

    case 'FETCH_FAQS_REJECTED':
    case 'SAVE_FAQ_REJECTED':
    case 'REMOVE_FAQ_REJECTED':
      return updateStateWithError(action.error, state);

    default:
      return state;
  }
}
