import { updateStateWithLoading, updateStateWithValue, updateStateWithError } from './';

const initialState = {
  loading: false,
  error: false,
  locations: [],
  location: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_LOCATIONS_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_LOCATIONS_FULFILLED':
      return updateStateWithValue('locations', action.data, state);

    case 'FECH_LOCATIONS_REJECTED':
      return updateStateWithError(action.error, state);

    case 'SAVE_LOCATION_PENDING':
      return updateStateWithLoading(state);

    case 'SAVE_LOCATION_FULFILLED':
    return updateStateWithValue('location', action.data, state);

    case 'SAVE_LOCATION_REJECTED':
      return updateStateWithError(action.error, state);

    default:
      return state;
  }
}