import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({title, path, icon, ...props}) => {
  var url = window.location.pathname;
  var urlPath = url.split("/").slice(-1)[0];

  const MenuItem = styled.li`
  height: 60px;
  line-height: 60px;
  color: ${props => path === urlPath ? '#FFF' : props.theme.skyBlue};
  cursor: pointer;
  font-size: 1.125rem;
  padding-left: 1rem;
  background-color: ${props => path === urlPath ? props.theme.darkBlueBackground : 'none'};
  transition: all ease-out  .1s;

  &:hover {
    color: #FFF;
    background-color: ${props => props.theme.darkBlueBackground}
  }

  svg {
    margin-right: 20px;
  }
`;

  return (
    <Link to={`/${path}`}>
      <MenuItem>
        <FontAwesomeIcon icon={['far', icon]} /> {title}
      </MenuItem>
    </Link>
  );
}
