import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

export const FestivalCard = ({festival, onClick, ...props}) => {
  const FestivalCard = styled.div`
    position: relative;
    margin: 0 12px 24px;
    width: 264px;
    min-height: 200px;
    background-image: url(${festival ? festival.photoURL : ''});
    transition: all .5s;
    background-size: cover;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    cursor: pointer;

    ${breakpoint('xs')`
      width: 100%;
    `}

    ${breakpoint('sm')`
      width: calc(50% - 24px);
    `}

    ${breakpoint('md')`
      width: 264px;
    `}

    &:hover {
      transform: scale(1.1);
    }
  `;

  const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border-radius: 8px;
    padding: 15px;

    h3 { color: #FFF; }

    p {
      margin: 0;

      svg {
        margin-right: .5rem;
      }
    }
  `;

  return (
    <FestivalCard>
      {festival && !onClick ? (
        <Link to={`/festival/${festival.festivalId}`}>
          <Overlay>
            <h3>{festival ? festival.name : 'Add New'}</h3>
            {festival &&
              <>
                <p>
                  <FontAwesomeIcon icon={['far', 'location-arrow']} /> {festival.location.locationName}
                </p>
                <p>
                <FontAwesomeIcon icon={['far', 'calendar-alt']} /> {moment(festival.startDate.startTime).format('M-D-YYYY')}
                </p>
              </>
            }
          </Overlay>
        </Link>
      ) : (
        <Overlay onClick={onClick}>
          <h3>{festival ? festival.name : 'Add New'}</h3>
          {festival &&
            <>
              <p>
                <FontAwesomeIcon icon={['far', 'location-arrow']} /> {festival.location.locationName}
              </p>
              <p>
              <FontAwesomeIcon icon={['far', 'calendar-alt']} /> {moment(festival.startDate.startTime).format('M-D-YYYY')}
              </p>
            </>
          }
        </Overlay>
      )}
    </FestivalCard>
  );
}
