import axiosClient from './axios-client';

export const fetchStages = (festivalId) => ({
  type: 'FETCH_STAGES',
  payload: axiosClient.get(`/admin/stages/${festivalId}`)
});

export const saveStage = (festivalId, stage) => ({
  type: 'SAVE_STAGE',
  payload: axiosClient.post(`/admin/stage/${festivalId}`, stage)
});

export const updateStage = (stage) => ({
  type: 'STAGE_UPDATED',
  data: stage
});
