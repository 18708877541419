import React from 'react';
import styled from 'styled-components';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FormButtons, Error, FormRow, FileUpload, DateFormWidget, Map } from '../controls';

const InfoWrapper = styled.div`
  display: flex;
`;

const LeftWrapper = styled.div`
  padding: 1rem;
  width: 75%;
`;

const RightWrapper = styled.div`
  padding: 1rem;
  width: 25%;
`;

const StyledFileUpload = styled(FileUpload)`
  width: 100%;
`;

const AttractionSchema = Yup.object().shape({
  attractionName: Yup.string().required('Attraction name is required')
});

class AttractionFormComponent extends React.Component {
  render() {
    const { attraction, onDateChange, handleSave, handleRemove, onLocationChange, onFileUpload, onFileDelete } = this.props;
    const { startTime, startTimeMS, endTime, endTimeMS } = attraction;
    const defaultValues = {
      attractionName: attraction.attractionName || '',
      attractionDescription: attraction.attractionDescription || '',
    };
    const dates = [{ startTime, startTimeMS, endTime, endTimeMS }];

    var fileList = [];

    if (attraction && attraction.photoURL) {
      fileList.push({
        uid: '-1',
        name: attraction.attractionPhoto,
        url: attraction.photoURL,
        status: 'done'
      });
    }

    console.log(fileList);
    console.log(attraction);

    return (
      <Formik
        ref={node => this.form = node}
        enableReinitialize
        initialValues={defaultValues}
        onSubmit={handleSave}
        validationSchema={AttractionSchema}
      >
        {
          ({ errors, touched }) => (
            <Form>
              <InfoWrapper>
                <LeftWrapper>
                  <FormRow label="AttractionName" errorName="attractionName">
                    <Field type="text" name="attractionName" placeholder="Name" />
                  </FormRow>
                  <DateFormWidget label="Dates & Times" dates={dates} handleDateChange={onDateChange} />
                </LeftWrapper>
                <RightWrapper>
                  <StyledFileUpload fileList={fileList} onFileUpload={onFileUpload} onFileDelete={onFileDelete} />
                </RightWrapper>
              </InfoWrapper>
              <FormRow errorName="location">
                <Map location={attraction.location} handleClick={onLocationChange} />
              </FormRow>
              <FormRow label="Description">
                <Field component="textarea" name="attractionDescription" placeholder="Attraction Description..." />
              </FormRow>
              <FormButtons showDelete={attraction.attractionId} label="Attraction" onDelete={handleRemove} />
            </Form>
          )
        }
      </Formik>
    );
  }
}

export const AttractionForm = AttractionFormComponent;
