import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterableTable from '../table';
import { PerformanceForm } from '../performances';
import { Button } from '../controls';
import { savePerformance, removePerformance,  } from '../../actions';

class StagePerformancesComponent extends React.Component {
  state = {
    showForm: false,
    selectedPerformance: {},
  };

  buildColumnDefinitions = () => {
    var columnDefs = {};
    columnDefs.performerName = {
      header: 'Performer',
      filterable: true,
      sortable: true,
      showViewLink: false,
      handleClick: true
    };
    columnDefs.startTime = {
      header: 'Start Time',
      filterable: false,
      sortable: true
    };
    columnDefs.endTime = {
      header: 'End Time',
      filterable: false,
      sortable: true,
    }
    return columnDefs;
  }

  openForm = (performance) => {
    const { stage } = this.props;
    this.setState({ selectedPerformance: { ...performance, stageId: stage.stageId }, showForm: true });
  }

  closeForm = () => {
    this.setState({ selectedPerformance: {}, showForm: false });
  }

  handleDateChange = (dates) => {
    const { selectedPerformance } = this.state;
    const updatedPerformance = { ...selectedPerformance, ...dates[0] };
    this.setState({ selectedPerformance: updatedPerformance });
  }

  performerUpdated = (performer) => {
    const { selectedPerformance } = this.state;
    const { performerId } = performer;
    this.setState({ selectedPerformance: { ...selectedPerformance, performer, performerId }});
  }

  savePerformance = (performance) => {
    const { handlePerformanceSave } = this.props;
    const { selectedPerformance } = this.state;
    const updatedPerformance = { ...selectedPerformance, ...performance };

    delete updatedPerformance.dates;
    delete updatedPerformance.startTime;
    delete updatedPerformance.endTime;
    delete updatedPerformance.performerName;
    delete updatedPerformance.stage;

    handlePerformanceSave(updatedPerformance)
      .then(() => {
        this.setState({ showForm: false, selectedPerformance: {} });
      })
      .catch(() => {
        console.log('rejected');
      });
  }

  deletePerformance = () => {
    const { selectedPerformance } = this.state;
    const { handlePerformanceDelete } = this.props;
    
    handlePerformanceDelete(selectedPerformance)
      .then(() => {
        this.setState({ showForm: false, selectedPerformance: {} });
      })
      .catch(() => {
        console.log('delete rejected');
      });
  }

  render() {
    var { performances, performers } = this.props;
    const { showForm, selectedPerformance } = this.state;

    console.log(performances);

    if (performances) {
      performances = performances.map(performance => ({
        ...performance,
        performerName: performance.performer.performerName,
        startTime: moment(performance.startTime).format('YYYY-MM-DD h:mm a'),
        endTime: moment(performance.endTime).format('YYYY-MM-DD h:mm a')
      }));
    }

    return (
      <>
        {showForm ? (
          <>
            <Button style={{ float: 'right' }} onClick={this.closeForm}>
              <FontAwesomeIcon icon={['far', 'arrow-left']} /> Back
            </Button>
            <PerformanceForm
              performance={selectedPerformance}
              handleSave={this.savePerformance}
              handleDateChange={this.handleDateChange}
              performers={performers}
              performerUpdated={this.performerUpdated}
              handleRemove={this.deletePerformance}
            />
          </>
        ) : (
          <FilterableTable
            data={performances || []}
            tableTitle=''
            columns={this.buildColumnDefinitions()}
            includeFilter={false}
            allowAddNewRecord={true}
            addNewRecordText='Performance'
            viewLinkBuilder={this.openForm}
            editHandler={this.openForm}
            toggleModal={this.openForm}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.performerStore.loading,
  festival: state.festivalStore.festival,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    savePerformance,
    removePerformance,
  }, dispatch)
});

export const StagePerformances = connect(mapStateToProps, mapDispatchToProps)(StagePerformancesComponent);
