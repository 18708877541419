import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { fetchAttractions, saveAttraction, removeAttraction } from '../../actions';
import FilterableTable from '../table';
import Portal from '../modal/Portal';
import { AttractionForm } from './';
import { Loading, FestivalHeader } from '../controls';
import { AssignedSponsors } from '../sponsors';
import { SectionNavbar } from '../section-navbar';

class AttractionsComponent extends React.Component {
  state = {
    showModal: false,
    selectedAttraction: {},
  }

  componentDidMount() {
    this.getAttractions();
  }

  getAttractions = () => {
    const { festival, actions } = this.props;
    actions.fetchAttractions(festival.festivalId);
  }

  buildColumnDefinitions = () => {
    var columnDefs = {};

    columnDefs.attractionName = {
      header: 'Attraction Name',
      filterable: true,
      sortable: true,
      showViewLink: false,
      handleClick: true
    };
    columnDefs.startTime = {
      header: 'Start Time',
      filterable: false,
      sortable: false
    };
    columnDefs.endTime = {
      header: 'End Time',
      filterable: false,
      sortable: false,
    };

    return columnDefs;
  }

  openModal = (attraction) => {
    const { attractions } = this.props;
    const selectedAttraction = attractions.find(a => a.attractionId === attraction.attractionId) || {};
    this.setState({ selectedAttraction, showModal: true });
  }

  handleDateChange = (dates) => {
    const { selectedAttraction } = this.state;
    const updatedAttraction = { ...selectedAttraction, ...dates[0] };
    this.setState({ selectedAttraction: updatedAttraction });
  }

  handleLocationChange = (location) => {
    const { selectedAttraction } = this.state;
    const updatedLocation = { ...selectedAttraction.location, ...location, radius: 50 };
    const updatedAttraction = { ...selectedAttraction, location: updatedLocation };
    this.setState({ selectedAttraction: updatedAttraction });
  }

  onFileUpload = (fileInfo) => {
    console.log(fileInfo);
    const { selectedAttraction } = this.state;
    const { filename, url } = fileInfo;
    this.setState({ selectedAttraction: { ...selectedAttraction, photoURL: url, attractionPhoto: filename } });
  }

  onFileDelete = () => {
    const { selectedAttraction } = this.state;
    this.setState({ selectedAttraction: { ...selectedAttraction, photoURL: null, attractionPhoto: null } });
  }

  handleSave = (attraction) => {
    const { actions: { saveAttraction, fetchAttractions }, festival } = this.props;
    const { selectedAttraction } = this.state;
    const updatedAttraction = { ...selectedAttraction, ...attraction };

    delete updatedAttraction.startTime;
    delete updatedAttraction.endTime;

    saveAttraction(festival.festivalId, updatedAttraction)
      .then(() => {
        fetchAttractions(festival.festivalId);
        this.setState({ showModal: false });
        NotificationManager.success('Attraction saved', '', 3000);
      })
      .catch(() => {
        NotificationManager.error('Attraction not saved', 'Error', 3000);
      });
  }

  handleRemove = () => {
    const { actions } = this.props;
    const { selectedAttraction } = this.state;

    actions.removeAttraction(selectedAttraction.attractionId)
      .then(() => {
        NotificationManager.success('Attraction removed', '', 3000);
        this.setState({ showModal: false, selectedAttraction: {} });
        this.getAttractions();
      })
      .catch(() => {
        NotificationManager.error('Attraction could not be removed', '', 3000);
      });
  }

  render() {
    const { attractions, loading } = this.props;
    const { selectedAttraction, showModal } = this.state;
    const tableData = attractions.map(attraction => ({
      ...attraction,
      startTime: attraction.startTime ? moment(attraction.startTime).format('YYYY-MM-DD h:mm a') : '-',
      endTime: attraction.endTime ? moment(attraction.endTime).format('YYYY-MM-DD h:mm a') : '-',
    }));

    const attractionForm = (
      <AttractionForm
        attraction={selectedAttraction}
        handleSave={this.handleSave}
        onDateChange={this.handleDateChange}
        handleRemove={this.handleRemove}
        onLocationChange={this.handleLocationChange}
        onFileUpload={this.onFileUpload}
        onFileDelete={this.onFileDelete}
      />
    );

    const sponsorsComponent = (
      <AssignedSponsors
        objectId={selectedAttraction.attractionId}
        objectType="attraction"
      />
    );

    const sections = [
      {
        title: 'Information',
        component: attractionForm,
      },
      {
        title: 'Sponsors',
        component: sponsorsComponent
      }
    ];

    return (
      <>
        <FestivalHeader
          title="Attractions"
          showExit={true}
          showAdd={true}
          addTitle="Add Attraction"
          addAction={this.openModal}
        />
        {loading ? (
          <Loading />
        ) : (
          <FilterableTable
            data={tableData}
            tableTitle=''
            columns={this.buildColumnDefinitions()}
            includeFilter={false}
            editHandler={this.openModal}
            toggleModal={this.openModal}
          />
        )}

        <Portal
          header={selectedAttraction ? selectedAttraction.attractionName || 'Add New Attraction' : 'Add New Attraction'}
          open={showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <SectionNavbar navItems={sections} />
        </Portal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  attractions: state.attractionStore.attractions,
  festival: state.festivalStore.festival,
  loading: state.attractionStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAttractions,
    saveAttraction,
    removeAttraction,
  }, dispatch)
});

export const Attractions = connect(mapStateToProps, mapDispatchToProps)(AttractionsComponent);
