import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Button } from '../controls';

const TableHeader = styled.header`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 2rem;

    h1{
      line-height: 1;
      margin-bottom: 0;
      order: 1;
      color: #73797f;
    }

    button{
      order: 2;
    }
  }
`;

const Table = styled.table`
  border-collapse: separate;
  width: 100%;
`;

const TH = styled.th`
  text-align: left;
  color: #73797f;
  height: 30px;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
`;

const TD = styled.td`
  color: ${props => props.theme.skyBlue}
  height: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-of-type {
    cursor: pointer;
  }
`;

const FilterRow = styled.div`
  margin: 0 0 1rem;
    max-width: rem-calc(400);
    order: 3;
    width: 100%;

    input{
      border-radius: 1.25rem;
      outline: 0;
    }
`

class FilterableTable extends Component{
  constructor(props){
    super(props);

    this.state = {
      filter: '',
      filteredRows: [],
      columns: [],  // column definitions. dictionary with field key, value has header, sortable, filterable, showViewLink fields
      rows: [], //expecting array of objects,
      sort: {
        key: null,
        order: 'desc'
      }
    };
  }

  componentDidMount(){
    const { data, columns } = this.props;

    this.setState({
      columns,
      rows: data,
      filteredRows: data
    });
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (prevProps.data.length !== data.length) {
      this.setState({
        rows: data,
        filteredRows: data
      });
    }
  }

  editRow = (event, row) => {
    event.preventDefault();
    this.props.editHandler(row);
  }

  filterByText = (e) => {
    let updatedList = this.state.rows;
    const filterText = e.target.value.toLowerCase();

    if (filterText !== '') {
      const filterableKeys = Object.keys(this.state.columns).filter(key => {
        const column = this.state.columns[key];
        return column.filterable;
      });
      updatedList = updatedList.filter(item => {
        return filterableKeys.some(key => this.matchesText(item[key], filterText));
        });
    } else {
      updatedList = this.state.rows;
    }

    this.setState({
      filteredRows: updatedList,
      filter: filterText
    });
  }

  matchesText = (value, filterText) => {
    if (!filterText || !filterText.length) {
      return true;
    }
    if (!value) {
      return false;
    }
    let tokens = filterText.split(" ");
    return tokens.every((token) => {
      return value.toLocaleLowerCase().includes(token.toLocaleLowerCase())
        || value.toLocaleLowerCase().includes(token.toLocaleLowerCase())
    });
  }

  getSortIcon = (key, column) => {
    if (!column.sortable) {
      return null;
    }
    if (this.state.sort.key === key) {
      if (this.state.sort.order === 'asc') {
        return <i className="fa fa-lg fa-arrow-up" />;
      } else {
        return <i className="fa fa-lg fa-arrow-down" />;
      }
    }
    return null;
  }

  sortRows = (key) => {
    const newSortOrder = this.state.sort.order === 'asc' ? 'desc' : 'asc';

    const orderedFilteredRows = _.orderBy(this.state.filteredRows, key, newSortOrder);
    var sort = { ...this.state.sort };
    sort.order = newSortOrder;
    sort.key = key;
    this.setState({ sort, filteredRows: orderedFilteredRows });
  }

  render(){
    const { addNewRecordText, allowAddNewRecord, includeFilter, tableTitle, toggleModal, editHandler, viewLinkBuilder, data } = this.props;
    const { filteredRows, columns } = this.state;

    return(
      <section className="filterable-table-wrapper">
        <TableHeader>
          <h1>{tableTitle}</h1>

          {includeFilter &&
            <FilterRow className="filter-records">
              <input type="text" id="filter-records" placeholder={`Search ${tableTitle}`} value={this.state.filter} onChange={this.filterByText} />
            </FilterRow>
          }

          {allowAddNewRecord &&
            <Button onClick={() => toggleModal({})}>
              <FontAwesomeIcon icon={['far', 'plus']} /> Add {addNewRecordText}
            </Button>
          }
        </TableHeader>
        <Table className="table filterable-table">
          <thead>
            <tr>
            {Object.keys(columns).map(key => {
              const column = columns[key];
              return <TH onClick={() => this.sortRows(key)} key={key}>{column.header} {this.getSortIcon(key, column)}</TH>;
            })}
            </tr>
          </thead>
          <tbody>
          {data.map((row, index) => {
            let cells = []; // array to hold all table cells
            {/* let viewUrl = viewLinkBuilder(row); */}
            // looping through all keys in each row and displaying all data
            Object.keys(columns).map(key => {
              const column = columns[key];
              const value = row[key];
              if (column.showViewLink) {
                cells.push(<TD key={key}><Link to={viewLinkBuilder(row)}>{value}</Link></TD>)
              } else if(column.handleClick) {
                cells.push(<TD key={key} onClick={() => editHandler(row)}>{value}</TD>);
              } else if(key === 'action_url'){
                if (editHandler) {
                  cells.push(<TD key={key}><Link to="#" onClick={(e) => this.editRow(e, row)}><i className="fas fa-pencil-alt" /></Link></TD>)
                } else {
                  cells.push(<TD key={key}><Link to={row[key]}><i className="fas fa-pencil-alt" /></Link></TD>)
                }
              } else {
                cells.push(<TD key={key+value}>{value}</TD>)
              }
              return cells;
            });
            // returning the row to build the table
            return <tr key={index}>{cells}</tr>;
          })}

          </tbody>
        </Table>
      </section>
    )
  }
}

export default FilterableTable;
