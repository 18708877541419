import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SideNavigation from '../side-navigation';
import Routes from '../../routes';

const MainContainer = styled.div`
  display: flex;
`;

const LeftColumn = styled.div`
  width: 250px;
  background-color: #f8f8f8;
  height: calc(100vh - 90px);
`;

const RightColumn = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2.5rem;
`;

const Wrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 90px);
`;

const MainComponent = ({ withSideNav, festival, ...props }) => (
  <MainContainer>
    {withSideNav &&
      <LeftColumn>
        <SideNavigation festival={festival} />
      </LeftColumn>
    }
    <Wrapper>
      <RightColumn>
        <Routes />
      </RightColumn>
    </Wrapper>
  </MainContainer>
);

const mapStateToProps = (state) => ({
  festival: state.festivalStore.festival
});

export default connect(mapStateToProps)(MainComponent);
