import React from 'react';
import { Upload, Icon, Modal } from 'antd';
import axiosClient from '../../actions/axios-client';

class PicturesWall extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
  };

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }

  handleChange = ({ fileList }) => this.setState({ fileList });

  customRequest = ({ file, onSuccess, onError }) => {
    const headers = { 'Content-Type': file.type };
    const { onFileUpload } = this.props;

    axiosClient.post('/admin/image', file, headers)
      .then(response => {
        onSuccess();
        onFileUpload(response.data);
      })
      .catch(error => {
        onError();
      });
  }

  render() {
    const { previewVisible, previewImage } = this.state;
    var { fileList, onFileDelete } = this.props;
    const uploadButton = (
      <div>
        <Icon type="camera" style={{fontSize: '24px', color: '#38abd8'}}/>
        <div className="ant-upload-text" style={{color: '#38abd8'}}>Upload</div>
      </div>
    );

    return (
      <div className="clearfix" style={{width: '100%'}}>
        <Upload
          accept="image/*"
          customRequest={this.customRequest}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onRemove={onFileDelete}
        >
          {fileList.length > 0 ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export const FileUpload = PicturesWall;
