import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_API_URL_PROD : process.env.REACT_APP_BASE_API_URL_DEV;

export default axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    withCredentials: true
});
