import React from 'react';
import { Field } from 'formik';
import { FormRow, DateTimeRow } from '../controls';
import moment from 'moment';

const defaultDateObject = { startTime: null, endTime: null, startTimeMS: null, endTimeMS: null };

class DateFormWidgetComponent extends React.Component {

  addDateRow = () => {
    const newDateRow = { ...defaultDateObject };
    const { handleDateChange, dates } = this.props;
    handleDateChange([...dates, newDateRow]);
  }

  deleteDateRow = (index) => {
    const { dates, handleDateChange } = this.props;
    dates.splice(index, 1);
    handleDateChange(dates);
  }

  dateChanged = (index, value) => {
    const { dates, handleDateChange } = this.props;
    dates[index].startTimeMS = value ? value.valueOf() : null;
    delete dates[index].startTime;
    handleDateChange(dates);
  }

  startTimeChanged = (index, value) => {
    const { dates, handleDateChange } = this.props

    delete dates[index].startTime;

    if (!value) {
      dates[index].startTimeMS =  null;
    } else {
      const date = moment(dates[index].startTimeMS).format('YYYY-MM-DD');
      const time = value.format('HH:mm');
      const startDate = moment(date + time, 'YYYY-MM-DD[T]HH:mm');
      dates[index].startTimeMS = startDate ? startDate.valueOf() : null;
    }

    handleDateChange(dates);
  }

  validateDates = (index, value) => {
    const { dates, required } = this.props;
    const date = dates[index];

    if (!required) {
      return null;
    }

    if (!(date.startTimeMS && date.endTimeMS)) {
      return 'Dates are required';
    }

    return null;
  }

  endTimeChanged = (index, value) => {
    const { dates, handleDateChange } = this.props;

    delete dates[index].endTime;

    if (!value) {
      dates[index].endTimeMS = null;
    } else {
      const date = moment(dates[index].startTimeMS).format('YYYY-MM-DD');
      const time = value.format('HH:mm');
      const endDate = moment(date + time, 'YYYY-MM-DD[T]HH:mm');
      dates[index].endTimeMS = endDate && value ? endDate.valueOf() : null;
    }

    handleDateChange(dates);
  }

  render() {
    var { dates, label, allowMultiple, required } = this.props;

    if (!dates || !dates.length) {
      dates = [defaultDateObject];
    }

    return (
      <>
        {dates.map((date, index) =>
          <FormRow key={date.startTimeMS || index} label={`${index === 0 ? label : ''}`} errorName={required ? 'dates' : null}>
            <Field
              validate={(value) => this.validateDates(index)}
              name="dates"
              render={({ field, form: { touched, errors } }) => {
                return (
                  <>
                    <DateTimeRow
                      index={index}
                      date={date.startTimeMS}
                      startTime={date.startTimeMS}
                      endTime={date.endTimeMS}
                      onRowAdd={this.addDateRow}
                      onRowDelete={this.deleteDateRow}
                      updateStartTime={this.updateStartTime}
                      updateEndTime={this.updateEndTime}
                      onDateChange={this.dateChanged}
                      onStartTimeChange={this.startTimeChanged}
                      onEndTimeChange={this.endTimeChanged}
                      allowMultiple={allowMultiple}
                    />
                  </>
                )
              }}
            />
          </FormRow>
        )}
      </>
    );
  }
}

export const DateFormWidget = DateFormWidgetComponent;
