import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonComponent = styled.button`
  min-width: 150px;
  height: 40px;
  border-radius: 20px;
`;

const LightButtonComponent = styled(ButtonComponent)`
  border: solid 1px ${props => props.theme.skyBlue};
  background-color: #ffffff;
  color: ${props => props.theme.skyBlue};

  &:hover {
    color: #FFF;
    background-color: ${props => props.theme.skyBlue};
  }
`;

const DarkButtonComponent = styled(ButtonComponent)`
  background-color: ${props => props.theme.skyBlue};
  color: #FFF;

  &:hover {
    background-color: #ffffff;
    color: ${props => props.theme.skyBlue};
  }
`;

const DeleteButtonComponent = styled(ButtonComponent)`
  background-color: #FFF;
  color: #c9302c;
  margin-right: 1rem;

  &:hover {
    background-color: #c9302c;
    color: #FFF;
  }
`;

const FormButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const Button = ({ mode, ...props }) => (
  <>
    {mode === 'dark' ? (
      <DarkButtonComponent {...props}>
        {props.children}
      </DarkButtonComponent>
    ) : (
      <LightButtonComponent {...props}>
        {props.children}
      </LightButtonComponent>
    )}
  </>
);

export const FormButtons = ({ title, showDelete, onDelete, ...props }) => (
  <FormButtonWrapper>
    {showDelete &&
      <DeleteButtonComponent onClick={onDelete} type="button">
        <FontAwesomeIcon icon={['far', 'trash']} /> Delete
      </DeleteButtonComponent>
    }
    <DarkButtonComponent type="submit">
      <FontAwesomeIcon icon={['far', 'save']} /> Save {title}
    </DarkButtonComponent>
  </FormButtonWrapper>
);
