import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from './';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 5px solid #f1f1f1;
  margin-bottom: 1rem;

  h1 {
    color: #73797f;
    font-weight: 400;
    font-size: 30;

    svg {
      color: ${props => props.theme.skyBlue};
      margin-right: 1rem;
    }
  }

  button:first-of-type {
    margin-right: 1rem;
  }
`;

export const FestivalHeader = ({ title, onSave, showSave, showAdd, addAction, addTitle, showExit, ...props }) => (
  <Wrapper>
    <div>
      <h1>
        <FontAwesomeIcon icon={['fal', 'calendar-alt']} /> {title}
      </h1>
    </div>
    <div>
      {showExit &&
        <Link to="/">
          <Button>
            <FontAwesomeIcon icon={['far', 'reply']} /> Dashboard
          </Button>
        </Link>
      }
      {showSave &&
        <Button onClick={onSave}>
          <FontAwesomeIcon icon={['far', 'save']} /> Save
        </Button>
      }
      {showAdd &&
        <Button onClick={addAction}>
          <FontAwesomeIcon icon={['far', 'plus']} /> {addTitle}
        </Button>
      }
    </div>
  </Wrapper>
);
