import axiosClient from './axios-client';

export const fetchPerformers = () => ({
  type: 'FETCH_PERFORMERS',
  payload: axiosClient.get('/admin/performers')
});

export const savePerformer = (performer) => ({
  type: 'SAVE_PERFORMER',
  payload: axiosClient.post('/admin/performer', performer)
});

export const fetchPerformerTypes = () => ({
  type: 'FETCH_PERFORMER_TYPES',
  payload: axiosClient.get('/admin/performer-types')
});

export const fetchPerformances = (festivalId) => ({
  type: 'FETCH_PERFORMANCES',
  payload: axiosClient.get(`/admin/performances/${festivalId}`)
});

export const savePerformance = (performance, festivalId) => ({
  type: 'SAVE_PERFORMANCE',
  payload: axiosClient.post(`/admin/performance/${festivalId}`, performance)
});

export const removePerformance = (performanceId) => ({
  type: 'REMOVE_PERFORMANCE',
  payload: axiosClient.delete(`/admin/performance/${performanceId}`)
});
