import React from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import { login } from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationManager } from 'react-notifications';
import { Redirect } from 'react-router-dom';

const FestylandStyle = styled.div`
  width: 286px;
  height: 72px;
  margin: 0 auto;
  margin-bottom: 30px;
  background-image: linear-gradient(105deg, #49c6e9, #2d98cc);
  font-family: Phosphate-Inline;
  font-size: 60px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

const InputStyle = styled.div`
  margin-bottom: 20px;

  input{
      width: 328px;
      height: 40px;
      border-radius: 20px;
      border: solid 1px #979797;
      background-color: #ffffff;
    }
`;

const ContainerStyle = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const SignInButton = styled.button`
  width: 150px;
  height: 40px;
  border-radius: 20px;
  background-image: linear-gradient(105deg, #49c6e9, #2d98cc);
`;

const SignInTextStyle = styled.span`
  width: 52px;
  height: 19px;
  font-family: Phosphate-Solid;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;

const ErrorMessageStyle = styled.div`
  color: red;
`;

const mapStateToProps = (state) => ({
  currentUser: state.authStore.currentUser,
  isAuthenticated: state.authStore.isAuthenticated,
  loading: state.authStore.loading
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ login }, dispatch)
});

class LoginComponent extends React.Component {

  validateForm(values){
    let errors = {};
    if(!values.accountName){
      errors.accountName = 'Required';
    }
    if(!values.password){
      errors.password = 'Required';
    }
    return errors;
  }

  submit = (values) => {
    this.props.actions.login(values)
    .then(() => NotificationManager.success('Login success', '', 3000))
    .catch(() => NotificationManager.error('Login unsuccessful', '', 3000));
  }


  render(){
    if (this.props.isAuthenticated){
      return <Redirect to="/" />
    }
    return (
        <ContainerStyle>
          <Formik
            ref={node => this.form = node}
            enableReinitialize
            initialValues={{ accountName: '', password: '' }}
            onSubmit={this.submit}
            validate={this.validateForm}
          >
            {
              ({ errors, touched }) => (
                <Form>
                  <FestylandStyle>Festyland</FestylandStyle>
                  <InputStyle>
                    <Field type="text" name="accountName" placeholder="Email" />
                    <ErrorMessageStyle>
                      <ErrorMessage name="accountName" />
                    </ErrorMessageStyle>
                  </InputStyle>
                  <InputStyle>
                    <Field type="password" name="password" placeholder="Password" />
                    <ErrorMessageStyle>
                      <ErrorMessage name="password" />
                    </ErrorMessageStyle>
                  </InputStyle>
                  <SignInButton type="submit">
                    <SignInTextStyle>Sign In</SignInTextStyle>
                  </SignInButton>
                </Form>
              )
            }
          </Formik>
        </ContainerStyle>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
