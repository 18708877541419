import React from 'react';
import styled from 'styled-components';
import { Form, Formik, Field,  } from 'formik';
import * as Yup from 'yup';
import { Button, FormButtons, FormRow, FileUpload, Loading } from '../controls';
import { Switch } from 'antd';

const InfoWrapper = styled.div`
  display: flex;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 75%;
  flex-wrap: wrap;
`;

const RightWrapper = styled.div`
  padding: 1rem;
  width: 25%;
`;

const StyledFileUpload = styled(FileUpload)`
  width: 100%;
`;

const FAQSchema = Yup.object().shape({
  faqName: Yup.string().required('FAQ name is required'),
  faqDescription: Yup.string().required('Description is required')
});

class FAQFormComponent extends React.Component {
  render() {
    const { faq, onFileUpload, loading, handleSave, handleRemove, onFileDelete } = this.props;
    const defaultValues = {
      faqName: faq.faqName || '',
      faqDescription: faq.faqDescription || '',
      priority: faq.priority || 0
    };

    var fileList = [];

    if (faq.photoURL) {
      fileList.push({
        uid: '-1',
        name: faq.faqPhoto,
        url: faq.photoURL,
        status: 'done'
      });
    }

    return (
      <>
        <Formik
          ref={node => this.form = node}
          enableReinitialize
          initialValues={defaultValues}
          onSubmit={handleSave}
          validationSchema={FAQSchema}
        >
          {
            ({ errors, touched }) => (
              <Form>
                <InfoWrapper>
                  <LeftWrapper>
                    <FormRow label="FAQ Name" errorName="faqName">
                      <Field type="text" name="faqName" placeholder="Name" />
                    </FormRow>
                    <FormRow label="Full Screen">
                      <Field
                        name="priority"
                        render={({ field: { onChange, ...field }, form: { setFieldValue } }) => (
                          <Switch defaultChecked={faq.priority} onChange={(isOn) => setFieldValue('priority', isOn ? 1 : 0)} />
                        )}
                      />
                    </FormRow>
                  </LeftWrapper>
                  <RightWrapper>
                    <FormRow>
                      <StyledFileUpload fileList={fileList} onFileUpload={onFileUpload} onFileDelete={onFileDelete} />
                    </FormRow>
                  </RightWrapper>
                </InfoWrapper>
                <FormRow label="Description" errorName="faqDescription">
                  <Field component="textarea" name="faqDescription" placeholder="FAQ Description..." />
                </FormRow>
                {loading && <Loading />}
                <FormButtons showDelete={faq.faqId} title="FAQ" onDelete={handleRemove} />
              </Form>
            )
          }
        </Formik>
      </>
    );
  }
}

export const FAQForm = FAQFormComponent;
