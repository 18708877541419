import React from 'react';
import styled from 'styled-components';
import { SectionNavItem } from './';

const SectionNavbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: 300px;
  height: 60px;
  border-radius: 30px;
  line-height: 60px;
  margin-bottom: 1.5rem;
`;

class SectionNavbarComponent extends React.Component {
  state = {
    selectedItem: 0
  };

  handleSelectionChanged = (selectedItem) => {
    this.setState({ selectedItem });
  }

  render() {
    const { navItems } = this.props;
    const { selectedItem } = this.state;
    const visibleComponent = navItems[selectedItem].component;

    return (
      <>
        <SectionNavbarWrapper>
          {navItems.map((navItem, index) =>
            <SectionNavItem
              key={index}
              title={navItem.title}
              index={index}
              isSelected={selectedItem === index}
              handleSelectionChanged={this.handleSelectionChanged}
            />
          )}
        </SectionNavbarWrapper>
        {visibleComponent}
      </>
    )
  }
}

export const SectionNavbar = SectionNavbarComponent;
