import React from 'react';
import { SectionNavbar } from '../section-navbar';
import { Festivals } from '../festivals';
import { Vendors } from '../vendors';
import { Performers } from '../performers';

export default ({ ...props }) => {
  const sections = [
    {
      title: 'Festivals',
      component: <Festivals />,
    },
    {
      title: 'Vendors',
      component: <Vendors />,
    },
    {
      title: 'Performers',
      component: <Performers />,
    }
  ];

  return <SectionNavbar navItems={sections} />;
}
