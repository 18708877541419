import React from 'react';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { Select } from 'antd';
import { Button, FormButtons, FormRow, Map } from '../controls';

const Option = Select.Option;

const VendorSchema = Yup.object().shape({
  vendor: Yup.object().required('Vendor is required')
});

export const FestivalVendorForm = ({ vendor, vendors, handleSave, handleRemove, onLocationChange, ...props }) => {
  const defaultValues = {
    description: vendor.description || '',
    vendor: vendor.vendor || {}
  };

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      onSubmit={handleSave}
      validationSchema={VendorSchema}
    >
      {
        ({ errors, touched }) => (
          <Form>
            <FormRow label="Vendor" errorName="vendor">
              <Field
                name="vendor.vendorName"
                render={({ field: { onChange, ...field }, form: { setFieldValue } }) => (
                  <Select
                    {...field}
                    showSearch
                    filterOption={true}
                    placeholder="Select a vendor"
                    defaultValue={vendor.vendor ? vendor.vendor.vendorName || '' : ''}
                    onChange={
                      (newValue) => setFieldValue('vendor', vendors.find(vendor => vendor.vendorName === newValue))
                    }
                  >
                    {vendors.map(vendor =>
                      <Option key={vendor.vendorId} value={vendor.vendorName}>{vendor.vendorName}</Option>
                    )}
                  </Select>
                )}
              />
            </FormRow>
            <FormRow label="Description">
              <Field component="textarea" name="description" placeholder="Festival Specific Vendor Description..." />
            </FormRow>
            <FormRow>
              <Map location={vendor.location} handleClick={onLocationChange} />
            </FormRow>
            <FormButtons showDelete={vendor.vendorId} title="Vendor" onDelete={handleRemove} />
          </Form>
        )
      }
    </Formik>
  )
}
