import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationManager } from 'react-notifications';
import { fetchSponsors, removeSponsor, associateSponsor, disassociateSponsor, saveSponsor } from '../../actions';
import { FestivalHeader, Loading } from '../controls';
import FilterableTable from '../table';
import Portal from '../modal';
import { SponsorForm } from './';

class SponsorsComponent extends React.Component {
  state = {
    showModal: false,
    selectedSponsor: {},
  };

  componentDidMount() {
    this.getSponsors();
  }

  getSponsors = () => {
    const { festival, actions } = this.props;
    actions.fetchSponsors(festival.festivalId);
  }

  buildColumnDefinitions = () => {
    var columnDefs = {};

    columnDefs.sponsorName = {
      header: 'Sponsor Name',
      filterable: true,
      sortable: true,
      showViewLink: false,
      handleClick: true
    };

    return columnDefs;
  }

  onFileUpload = (fileInfo) => {
    const { selectedSponsor } = this.state;
    const { filename, url } = fileInfo;
    this.setState({ selectedSponsor: { ...selectedSponsor, photoURL: url, sponsorPhoto: filename } });
  }

  onFileDelete = () => {
    const { selectedSponsor } = this.state;

    selectedSponsor.photoURL = null;
    selectedSponsor.sponsorPhoto = null;

    this.setState({ selectedSponsor });
  }

  handleSave = (sponsor) => {
    const { selectedSponsor } = this.state;
    const { festival: { festivalId }, actions: { saveSponsor } } = this.props;
    const updatedSponsor = { ...selectedSponsor, ...sponsor };

    saveSponsor(updatedSponsor, festivalId)
      .then(() => {
        NotificationManager.success('Sponsor saved', '', 3000);
        this.setState({ showModal: false });
        this.getSponsors();
      })
      .catch(() => NotificationManager.error('Sponsor could not be saved', '', 3000));
  }

  handleRemove = () => {
    const { selectedSponsor } = this.state;
    const { actions } = this.props;

    actions.removeSponsor(selectedSponsor.sponsorId)
      .then(() => {
        NotificationManager.success('Sponsor removed', '', 3000);
        this.setState({ showModal: false, selectedSponsor: {} });
        this.getSponsors();
      })
      .catch(() => {
        NotificationManager.error('Sponsor could not be removed', '', 3000);
      });
  }

  openModal = (sponsor) => {
    const { sponsors } = this.props;
    const selectedSponsor = sponsors.find(s => s.sponsorId === sponsor.sponsorId) || {};
    this.setState({ selectedSponsor, showModal: true });
  }

  render() {
    const { sponsors, loading } = this.props;
    const { showModal, selectedSponsor } = this.state;

    return (
      <>
        <FestivalHeader
          title="Sponsors"
          showExit={true}
          showAdd={true}
          addTitle="Add Sponsor"
          addAction={this.openModal}
        />
        {loading ? (
          <Loading />
        ) : (
          <FilterableTable
            data={sponsors}
            tableTitle=''
            columns={this.buildColumnDefinitions()}
            includeFilter={false}
            editHandler={this.openModal}
            toggleModal={this.openModal}
          />
        )}

        <Portal
          header={selectedSponsor ? selectedSponsor.sponsorName || 'Add New Sponsor' : 'Add New Sponsor'}
          open={showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <SponsorForm
            sponsor={selectedSponsor}
            handleSave={this.handleSave}
            handleRemove={this.handleRemove}
            onFileUpload={this.onFileUpload}
            onFileDelete={this.onFileDelete}
          />
        </Portal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sponsors: state.sponsorStore.sponsors,
  festival: state.festivalStore.festival,
  loading: state.sponsorStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchSponsors,
    removeSponsor,
    associateSponsor,
    disassociateSponsor,
    saveSponsor,
  }, dispatch)
});

export const Sponsors = connect(mapStateToProps, mapDispatchToProps)(SponsorsComponent);
