import axiosClient from './axios-client';

export const fetchFAQS = (festivalId) => ({
  type: 'FETCH_FAQS',
  property: 'faqs',
  payload: axiosClient.get(`/admin/faqs/${festivalId}`)
});

export const saveFAQ = (faq, festivalId) => ({
  type: 'SAVE_FAQ',
  property: 'faq',
  payload: axiosClient.post(`/admin/faq/${festivalId}`, faq)
});

export const removeFAQ = (faqId) => ({
  type: 'REMOVE_FAQ',
  property: 'faq',
  payload: axiosClient.delete(`/admin/faq/${faqId}`)
});
