import React from 'react';
import styled from 'styled-components';

const StyledError = styled.p`
  color: red;
  margin: 0 1rem;
`;

export const Error = ({ name, ...props }) => (
  <StyledError>
    {props.children}
  </StyledError>
);
