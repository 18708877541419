import axiosClient from './axios-client';

export const fetchVendors = (festivalId) => ({
  type: 'FETCH_VENDORS',
  payload: axiosClient.get(`/admin/vendors/${festivalId}`)
});

export const fetchAllVendors = () => ({
  type: 'FETCH_ALL_VENDORS',
  payload: axiosClient.get('/admin/vendors')
});

export const fetchVendorCategories = () => ({
  type: 'FETCH_VENDOR_CATEGORIES',
  payload: axiosClient.get('/admin/vendor-categories')
});

export const saveVendor = (vendor) => ({
  type: 'SAVE_VENDOR',
  payload: axiosClient.post('/admin/vendor', vendor)
});

export const saveFestivalVendor = (vendor, festivalId) => ({
  type: 'SAVE_VENDOR',
  payload: axiosClient.post(`/admin/vendor/${festivalId}`, vendor)
});

export const removeVendor = (vendorId, festivalId) => ({
  type: 'REMOVE_VENDOR',
  payload: axiosClient.delete(`/admin/vendor/${festivalId}/${vendorId}`)
});
