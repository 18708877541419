import React from 'react';
import styled from 'styled-components';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FormButtons, FileUpload, FormRow, Map } from '../controls';
import { Select } from 'antd';

const Option = Select.Option;

const InfoWrapper = styled.div`
  display: flex;
`;

const LeftWrapper = styled.div`
  padding: 1rem;
  width: 75%;
`;

const RightWrapper = styled.div`
  padding: 1rem;
  width: 25%;
`;

const StyledFileUpload = styled(FileUpload)`
  width: 100%;
`;

const ServiceSchema = Yup.object().shape({
  serviceName: Yup.string().required('Service name is required'),
  type: Yup.string().required('Service type is required')
});

class ServiceFormComponent extends React.Component {
  render() {
    const { service, types, handleSave, handleLocationChange, onFileUpload, onFileDelete, handleRemove } = this.props;
    const defaultValues = {
      serviceName: service.serviceName || '',
      serviceDescription: service.serviceDescription || '',
      type: service.type || [],
    };

    var fileList = [];

    if (service && service.photoURL) {
      fileList.push({
        uid: '-1',
        name: service.servicePhoto,
        url: service.photoURL,
        status: 'done'
      });
    }

    return (
      <Formik
        ref={node => this.form = node}
        enableReinitialize
        initialValues={defaultValues}
        onSubmit={handleSave}
        validationSchema={ServiceSchema}
      >
        {
          ({ errors, touched }) => (
            <Form>
              <InfoWrapper>
                <LeftWrapper>
                  <FormRow label="Service Name" errorName="serviceName">
                    <Field type="text" name="serviceName" placeholder="Name" />
                  </FormRow>
                  <FormRow label="Type" errorName="type">
                    <Field
                      name="type"
                      render={({ field: { onChange, ...field }, form: { setFieldValue } }) => (
                        <Select
                          {...field}
                          mode="tags"
                          placeholder="Select or enter a type"
                          defaultValue={[service.type]}
                          onChange={(newValue) => setFieldValue('type', newValue[newValue.length - 1])}
                        >
                          {types.map(type =>
                            <Option key={type} value={type}>{type}</Option>
                          )}
                        </Select>
                      )}
                    />
                  </FormRow>
                </LeftWrapper>
                <RightWrapper>
                  <StyledFileUpload fileList={fileList} onFileUpload={onFileUpload} onFileDelete={onFileDelete} />
                </RightWrapper>
              </InfoWrapper>
              <FormRow label="Description">
                <Field component="textarea" name="serviceDescription" placeholder="Service Description..." />
              </FormRow>
              <FormRow>
                <Map location={service.location} handleClick={handleLocationChange} />
              </FormRow>
              <FormButtons showDelete={service.serviceId} title="Service" onDelete={handleRemove} />
            </Form>
          )
        }
      </Formik>
    );
  }
}

export const ServiceForm = ServiceFormComponent;
