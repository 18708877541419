import { updateStateWithLoading, updateStateWithValue, updateStateWithError } from './';

const initialState = {
  loading: false,
  error: false,
  performers: [],
  performer: {},
  performances: [],
  performance: {},
  types: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_PERFORMERS_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_PERFORMERS_FULFILLED':
      return updateStateWithValue('performers', action.data, state);

    case 'FETCH_PERFORMERS_REJECTED':
      return updateStateWithError(action.error, state);

    case 'SAVE_PERFORMER_PENDING':
      return updateStateWithLoading(state);

    case 'SAVE_PERFORMER_FULFILLED':
      return updateStateWithValue('performer', action.data, state);

    case 'SAVE_PERFORMER_REJECTED':
      return updateStateWithError(action.error, state);

    case 'FETCH_PERFORMER_TYPES_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_PERFORMER_TYPES_FULFILLED':
      return updateStateWithValue('types', action.data, state);

    case 'FETCH_PERFORMER_TYPES_REJECTED':
      return updateStateWithError(action.error, state);

    case 'FETCH_PERFORMANCES_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_PERFORMANCES_FULFILLED':
      return updateStateWithValue('performances', action.data, state);

    case 'FETCH_PERFORMANCES_REJECTED':
      return updateStateWithError(action.error, state);

    case 'SAVE_PERFORMANCE_PENDING':
      return updateStateWithLoading(state);

    case 'SAVE_PERFORMANCE_FULFILLED':
      return updateStateWithValue('performer', action.data, state);

    case 'SAVE_PERFORMANCE_REJECTED':
      return updateStateWithError(action.error, state);

    case 'REMOVE_PERFORMANCE_PENDING':
      return updateStateWithLoading(state);

    case 'REMOVE_PERFORMANCE_FULFILLED':
      return updateStateWithValue('performer', {}, state);

    case 'REMOVE_PERFORMANCE_REJECTED':
      return updateStateWithError(action.error, state);

    default:
      return state;
  }
}
