import axiosClient from './axios-client';

export const fetchSponsors = (festivalId) => ({
  type: 'FETCH_SPONSORS',
  property: 'sponsors',
  payload: axiosClient.get(`/admin/sponsors/${festivalId}`)
});

export const saveSponsor = (sponsor, festivalId) => ({
  type: 'SAVE_SPONSOR',
  property: 'sponsor',
  payload: axiosClient.post(`/admin/sponsor/${festivalId}`, sponsor)
});

export const removeSponsor = (sponsorId) => ({
  type: 'REMOVE_SPONSOR',
  property: 'sponsor',
  payload: axiosClient.delete(`/admin/sponsor/${sponsorId}`)
});

export const associateSponsor = (festivalId, sponsorId, objectType, objectId) => ({
  type: 'SAVE_SPONSOR_OBJECT',
  property: 'sponsor',
  payload: axiosClient.put(`/admin/sponsor/${festivalId}/${sponsorId}/${objectType}/${objectId}`)
});

export const disassociateSponsor = (festivalId, sponsorId, objectType, objectId) => ({
  type: 'REMOVE_SPONSOR_OBJECT',
  property: 'sponsor',
  payload: axiosClient.delete(`/admin/sponsor/${festivalId}/${sponsorId}/${objectType}/${objectId}`)
});

export const fetchSponsorsForObject = (festivalId, objectType, objectId) => ({
  type: 'FETCH_SPONSORS_OBJECT',
  property: 'objectSponsors',
  payload: axiosClient.get(`/admin/sponsors/${festivalId}/${objectType}/${objectId}`)
});
