import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchFestivals } from '../../actions';
import { FestivalCard, FestivalForm } from './';
import { Loading, FestivalHeader } from '../controls';
import Portal from '../modal';

const FestivalsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4rem;
`;

class FestivalsComponent extends React.Component {
  state = {
    showModal: false,
  };

  componentDidMount() {
    const { fetchFestivals } = this.props.actions;

    fetchFestivals();
  }

  addNewFestival = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
  }

  render() {
    const { festivals, loading } = this.props;
    const { showModal } = this.state;

    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <FestivalsWrapper>
            <FestivalCard festival={null} onClick={this.addNewFestival} />
            {festivals.map(festival =>
              <FestivalCard festival={festival} key={festival.festivalId} />
            )}
          </FestivalsWrapper>
        )}

        <Portal
          header="Add New Festival"
          open={showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <FestivalForm />
        </Portal>

      </>
    )
  }
}

const mapStateToProps = (state) => ({
  festivals: state.festivalStore.festivals,
  loading: state.festivalStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchFestivals
  }, dispatch)
});

export const Festivals = connect(mapStateToProps, mapDispatchToProps)(FestivalsComponent);
