import React from 'react';
import styled from 'styled-components';
import DatePicker from 'antd/lib/date-picker';
import TimePicker from 'antd/lib/time-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const DateTimeWrapper = styled.div`
  margin-right: 1.3rem;
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  border: 1px solid #979797;
  border-radius: 20px;
  outline: none;
  box-shadow: none;

  input {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    height: 40px;
    padding: 0 1rem;
    line-height: 40px;
  }

  i {
    color: ${props => props.theme.skyBlue};
    font-size: 1rem;
  }
`;

const StyledTimePicker = styled(TimePicker)`
  .ant-time-picker-icon > i {
    color: ${props => props.theme.skyBlue};
    font-size: 1rem;
  }
`;

const ActionButton = styled.button`
  background-color: #FFF;
  color: ${props => props.theme.skyBlue};
  border: none;
  font-size: 1.3rem;
`;

const BottomLabel = styled.p`
  margin: 0;
  padding-left: 1rem;
  line-height: normal;
  color: ${props => props.theme.lightTextColor};
  font-style: italic;
`;

export const DateTimeRow = ({ date, startTime, endTime, onRowAdd, onRowDelete, index, allowMultiple, ...props }) => {
  return (
    <Wrapper>
      <DateTimeWrapper>
        <StyledDatePicker
          defaultValue={date ? moment(date) : null}
          onChange={(date, string) => props.onDateChange(index, date)}
        />
        <BottomLabel>Date</BottomLabel>
      </DateTimeWrapper>
      <DateTimeWrapper>
        <StyledTimePicker
          use12Hours
          format="h:mm A"
          defaultValue={startTime ? moment(startTime) : null}
          onChange={(time, string) => props.onStartTimeChange(index, time)}
        />
        <BottomLabel>Start Time</BottomLabel>
      </DateTimeWrapper>
      <DateTimeWrapper>
        <StyledTimePicker
          use12Hours
          format="h:mm A"
          defaultValue={endTime ? moment(endTime) : null}
          onChange={(time, string) => props.onEndTimeChange(index, time)}
        />
        <BottomLabel>End Time</BottomLabel>
      </DateTimeWrapper>
      {allowMultiple &&
        <>
          <ActionButton onClick={e => {
            e.preventDefault();
            onRowAdd();
          }}>
            <FontAwesomeIcon icon={['far', 'plus-circle']} />
          </ActionButton>
          <ActionButton onClick={e => {
            e.preventDefault();
            onRowDelete(index);
          }}>
            <FontAwesomeIcon icon={['far', 'trash-alt']} />
          </ActionButton>
        </>
      }
    </Wrapper>
  );
}
