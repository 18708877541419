import React from 'react';
import styled from 'styled-components';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormRow, FileUpload } from '../controls';
import { Select } from 'antd';

const Option = Select.Option;

const InfoWrapper = styled.div`
  display: flex;
`;

const LeftWrapper = styled.div`
  padding: 1rem;
  width: 75%;
`;

const RightWrapper = styled.div`
  padding: 1rem;
  width: 25%;
`;

const StyledFileUpload = styled(FileUpload)`
  width: 100%;
`;

const PerformerSchema = Yup.object().shape({
  performerName: Yup.string().required('Performer name is required')
});

class PerformerFormComponent extends React.Component {

  savePerformer = (values) => {
    const { handleSave } = this.props;
    handleSave(values);
  }

  render() {
    const { performer, handleSave, performerTypes, onFileUpload, onFileDelete } = this.props;
    const defaultValues = {
      performerDescription: performer.performerDescription || '',
      performerName: performer.performerName || '',
      hometown: performer.hometown || '',
      type: performer.type || undefined,
      subtype: performer.subtype || undefined,
      links: performer.links || {
        instagram: '',
        twitter: '',
        facebook: '',
        web: '',
      }
    };

    const types = performerTypes.types || [];
    const subtypes = performerTypes.subtypes || [];

    var fileList = [];

    if (performer.photoURL) {
      fileList.push({
        uid: '-1',
        name: performer.performerPhoto,
        url: performer.photoURL,
        status: 'done'
      });
    }

    return (
      <Formik
        ref={node => this.form = node}
        enableReinitialize
        initialValues={defaultValues}
        onSubmit={handleSave}
        validationSchema={PerformerSchema}
      >
        {
          ({ errors, touched }) => (
            <Form>
              <InfoWrapper>
                <LeftWrapper>
                  <FormRow label="Performer Name" errorName="performerName">
                    <Field type="text" name="performerName" placeholder="Name" />
                  </FormRow>
                  <FormRow label="Hometown">
                    <Field type="text" name="hometown" placeholder="Hometown" />
                  </FormRow>
                  <FormRow label="Type" errorName="type">
                    <Field
                      name="type"
                      render={({ field: { onChange, ...field }, form: { setFieldValue } }) => (
                        <Select
                          {...field}
                          mode="tags"
                          placeholder="Select or enter a type"
                          defaultValue={[performer.type]}
                          onChange={(newValue) => setFieldValue('type', newValue[newValue.length - 1])}
                        >
                          {types.map(type =>
                            <Option key={type} value={type}>{type}</Option>
                          )}
                        </Select>
                      )}
                    />
                  </FormRow>
                  <FormRow label="Subtype">
                    <Field
                      name="subtype"
                      render={({ field: { onChange, ...field }, form: { setFieldValue } }) => (
                        <Select
                          {...field}
                          mode="tags"
                          placeholder="Select or enter a subtype"
                          defaultValue={[performer.subtype]}
                          onChange={(newValue) => setFieldValue('subtype', newValue[newValue.length - 1])}
                        >
                          {subtypes.map(subtype =>
                            <Option key={subtype} value={subtype}>{subtype}</Option>
                          )}
                        </Select>
                      )}
                    />
                  </FormRow>
                </LeftWrapper>
                <RightWrapper>
                  <FormRow>
                    <StyledFileUpload fileList={fileList} onFileUpload={onFileUpload} onFileDelete={onFileDelete} />
                  </FormRow>
                </RightWrapper>
              </InfoWrapper>
              <FormRow label="Notes">
                <Field component="textarea" name="performerDescription" placeholder="Performer Biography..." />
              </FormRow>
              <FormRow label={<FontAwesomeIcon icon={['fal', 'globe']} />}>
                <Field type="text" name="links.web" placeholder="Website" />
              </FormRow>
              <FormRow label={<FontAwesomeIcon icon={['fab', 'facebook']} />}>
                <Field type="text" name="links.facebook" placeholder="Facebook" />
              </FormRow>
              <FormRow label={<FontAwesomeIcon icon={['fab', 'twitter']} />}>
                <Field type="text" name="links.twitter" placeholder="Twitter" />
              </FormRow>
              <FormRow label={<FontAwesomeIcon icon={['fab', 'instagram']} />}>
                <Field type="text" name="links.instagram" placeholder="Instagram" />
              </FormRow>
              <Button style={{ float: 'right', marginTop: '1rem' }}mode="dark">Save Performer</Button>
            </Form>
          )
        }
      </Formik>
    );
  }
}

export const PerformerForm = PerformerFormComponent;
