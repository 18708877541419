import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { fetchAllVendors, saveVendor, fetchVendorCategories, removeVendor } from '../../actions';
import FilterableTable from '../table';
import Portal from '../modal/Portal';
import { VendorForm } from './';
import { Loading, FestivalHeader } from '../controls';

class VendorsComponent extends React.Component {
  state = {
    showModal: false,
    selectedVendor: {},
  }

  componentDidMount() {
    this.fetchVendorsAndCategories();
  }

  fetchVendorsAndCategories = () => {
    const { actions: { fetchAllVendors, fetchVendorCategories } } = this.props;
    fetchAllVendors();
    fetchVendorCategories();
  }

  buildColumnDefinitions = () => {
    var columnDefs = {};

    columnDefs.vendorName = {
      header: 'Vendor Name',
      filterable: true,
      sortable: true,
      showViewLink: false,
      handleClick: true
    };
    columnDefs.category = {
      header: 'Category',
      filterable: false,
      sortable: false
    };
    columnDefs.subcategory = {
      header: 'Subcategory',
      filterable: false,
      sortable: false
    };

    return columnDefs;
  }

  onFileUpload = (fileInfo) => {
    const { selectedVendor } = this.state;
    const { url, filename } = fileInfo;
    this.setState({ selectedVendor: { ...selectedVendor, photoURL: url, vendorPhoto: filename } }, () => console.log(this.state.selectedVendor));
  }

  onFileDelete = () => {
    const { selectedVendor } = this.state;
    this.setState({ selectedVendor: { ...selectedVendor, photoURL: null, vendorPhoto: null } });
  }

  handleSave = (vendor) => {
    const { selectedVendor } = this.state;
    const { actions: { saveVendor } } = this.props;
    const updatedVendor = { ...selectedVendor, ...vendor };

    saveVendor(updatedVendor)
      .then(() => {
        NotificationManager.success('Vendor saved', '', 3000);
        this.setState({ showModal: false });
        this.fetchVendorsAndCategories();
      })
      .catch(() => NotificationManager.error('Vendor could not be saved', '', 3000));
  }

  openModal = (vendor) => {
    const { vendors } = this.props;
    const selectedVendor = vendors.find(v => v.vendorId === vendor.vendorId) || {};
    this.setState({ selectedVendor, showModal: true });
  }

  render() {
    const { vendors, vendorCategories, loading } = this.props;
    const { selectedVendor, showModal } = this.state;

    return (
      <>
        <FestivalHeader title="Vendors" showAdd={true} addTitle="Add Vendor" addAction={this.openModal} />
        {loading ? (
          <Loading />
        ) : (
          <FilterableTable
            data={vendors}
            tableTitle=''
            columns={this.buildColumnDefinitions()}
            includeFilter={false}
            viewLinkBuilder={this.buildViewLink}
            editHandler={this.openModal}
            toggleModal={this.openModal}
          />
        )}

        <Portal
          header={selectedVendor.vendorName || 'Add New Vendor'}
          open={showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <VendorForm
            vendorCategories={vendorCategories}
            vendor={selectedVendor}
            handleSave={this.handleSave}
            onFileUpload={this.onFileUpload}
            onFileDelete={this.onFileDelete}
          />
        </Portal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  vendors: state.vendorStore.vendors,
  vendorCategories: state.vendorStore.vendorCategories,
  festival: state.festivalStore.festival,
  loading: state.vendorStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllVendors,
    saveVendor,
    fetchVendorCategories,
    removeVendor,
  }, dispatch)
});

export const Vendors = connect(mapStateToProps, mapDispatchToProps)(VendorsComponent);
