import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { fetchServices, saveService, removeService, fetchServiceTypes } from '../../actions';
import FilterableTable from '../table';
import Portal from '../modal/Portal';
import { ServiceForm } from './';
import { Loading, FestivalHeader } from '../controls';
import { AssignedSponsors } from '../sponsors';
import { SectionNavbar } from '../section-navbar';

class ServicesComponent extends React.Component {
  state = {
    showModal: false,
    selectedService: {},
  }

  componentDidMount() {
    this.getServices();
  }

  getServices = () => {
    const { festival, actions } = this.props;
    actions.fetchServices(festival.festivalId);
    actions.fetchServiceTypes();
  }

  buildColumnDefinitions = () => {
    var columnDefs = {};

    columnDefs.serviceName = {
      header: 'Service Name',
      filterable: true,
      sortable: true,
      showViewLink: false,
      handleClick: true
    };
    columnDefs.type = {
      header: 'Type',
      filterable: false,
      sortable: false
    };

    return columnDefs;
  }

  onFileUpload = (fileInfo) => {
    const { selectedService } = this.state;
    const { filename, url } = fileInfo;
    this.setState({ selectedService: { ...selectedService, photoURL: url, servicePhoto: filename } });
  }

  onFileDelete = () => {
    const { selectedService } = this.state;
    this.setState({ selectedService: { ...selectedService, photoURL: null, servicePhoto: null } });
  }

  openModal = (service) => {
    const { services } = this.props;
    const selectedService = services.find(s => s.serviceId === service.serviceId) || {};
    this.setState({ selectedService, showModal: true });
  }

  handleLocationChange = (location) => {
    const { selectedService } = this.state;
    const updatedLocation = { ...selectedService.location, ...location, radius: 50 };
    const updatedService = { ...selectedService, location: updatedLocation };
    this.setState({ selectedService: updatedService });
  }

  handleSave = (service) => {
    const { actions: { saveService, fetchServices, fetchServiceTypes }, festival } = this.props;
    const { selectedService } = this.state;
    const updatedService = { ...selectedService, ...service };

    saveService(updatedService, festival.festivalId)
      .then(() => {
        fetchServices(festival.festivalId);
        fetchServiceTypes(festival.festivalId);
        this.setState({ showModal: false });
        NotificationManager.success('Service saved', '', 3000);
      })
      .catch(() => {
        NotificationManager.error('Service not saved', 'Error', 3000);
      });
  }

  handleRemove = () => {
    const { actions } = this.props;
    const { selectedService } = this.state;

    actions.removeService(selectedService.serviceId)
      .then(() => {
        NotificationManager.success('Service removed', '', 3000);
        this.setState({ selectedService: {}, showModal: false });
        this.getServices();
      })
      .catch(() => {
        NotificationManager.error('Service could not be removed', '', 3000);
      });
  }

  render() {
    const { services, serviceTypes, loading } = this.props;
    const { selectedService, showModal } = this.state;

    const serviceForm = (
      <ServiceForm
        types={serviceTypes}
        service={selectedService}
        handleSave={this.handleSave}
        handleRemove={this.handleRemove}
        handleLocationChange={this.handleLocationChange}
        onFileDelete={this.onFileDelete}
        onFileUpload={this.onFileUpload}
      />
    );

    const sponsorsComponent = (
      <AssignedSponsors
        objectId={selectedService.serviceId}
        objectType="service"
      />
    );

    const sections = [
      {
        title: 'Information',
        component: serviceForm,
      },
      {
        title: 'Sponsors',
        component: sponsorsComponent,
      }
    ];

    return (
      <>
        <FestivalHeader
          title="Services"
          showExit={true}
          showAdd={true}
          addTitle="Add Service"
          addAction={this.openModal}
        />
        {loading ? (
          <Loading />
        ) : (
          <FilterableTable
            data={services}
            tableTitle=''
            columns={this.buildColumnDefinitions()}
            includeFilter={false}
            addNewRecordText='Service'
            editHandler={this.openModal}
            toggleModal={this.openModal}
          />
        )}

        <Portal
          header={selectedService ? selectedService.serviceName || 'Add New Service' : 'Add New Service'}
          open={showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <SectionNavbar navItems={sections} />
        </Portal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  services: state.serviceStore.services,
  serviceTypes: state.serviceStore.serviceTypes,
  festival: state.festivalStore.festival,
  loading: state.serviceStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchServices,
    fetchServiceTypes,
    saveService,
    removeService,
  }, dispatch)
});

export const Services = connect(mapStateToProps, mapDispatchToProps)(ServicesComponent);
