import React from 'react';
import { StageForm, StagePerformances } from './';
import { SectionNavbar } from '../section-navbar';
import { AssignedSponsors } from '../sponsors';

class StageFormContainerComponent extends React.Component {
  state = {
    selectedItem: 0
  };

  handleSelectionChanged = (selectedItem) => {
    const { stage } = this.props;

    if (stage.stageId) {
      this.setState({ selectedItem });
    }
  }

  render() {
    const { stage, onStageSave, onFileUpload, onFileDelete, onPerformanceSave, onPerformanceDelete, onDateChange, onLocationChange, performers } = this.props;
    const formComponent = (
      <StageForm
        stage={stage}
        onStageSave={onStageSave}
        onDateChange={onDateChange}
        onLocationChange={onLocationChange}
        onFileDelete={onFileDelete}
        onFileUpload={onFileUpload}
      />
    );

    const performancesComponent = (
      <StagePerformances
        performances={stage.performances}
        handlePerformanceSave={onPerformanceSave}
        handlePerformanceDelete={onPerformanceDelete}
        stage={stage}
        performers={performers}
      />
    );

    const sponsorsComponent = (
      <AssignedSponsors
        objectId={stage.stageId}
        objectType="stage"
      />
    );

    const sections = [
      {
        title: "Information",
        component: formComponent
      },
      {
        title: 'Performances',
        component: performancesComponent,
      },
      {
        title: 'Sponsors',
        component: sponsorsComponent
      }
    ];


    return <SectionNavbar navItems={sections} />;
  }
}

export const StageFormContainer = StageFormContainerComponent;
