import React from 'react';
import styled from 'styled-components';
import { Error } from './';
import { ErrorMessage } from 'formik';

const FormRowComponent = styled.div`
  display: flex;
  line-height: 40px;
  width: 100%;
  padding: .5rem 0;
`;

const LabelWrapper = styled.div`
  width: 200px;
  text-align: right;
  line-height: normal;
  margin-top: 10px;
  margin-right: 1rem;

  label {
    text-transform: uppercase;
    font-size: .9rem;

    svg {
      font-size: 1.5rem;
      color: ${props => props.theme.skyBlue};
    }
  }
`;

const FieldWrapper = styled.div`
  width: 100%;

  input, textarea, .ant-select {
    width: 100%;
  }
`;

export const FormRow = ({ label, errorName, ...props }) => (
  <>
    <FormRowComponent>
      <LabelWrapper>
        <label>{label || ''}</label>
      </LabelWrapper>
      <FieldWrapper>
        {props.children}
        {errorName &&
          <ErrorMessage name={errorName || ''} component={Error} />
        }
      </FieldWrapper>
    </FormRowComponent>
  </>
);
