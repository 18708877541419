import React from 'react';
import styled from 'styled-components';

export const SectionNavItem = ({ index, isSelected, handleSelectionChanged, title, ...props }) => {
  const Item = styled.div`
    background-color: ${props => isSelected ? props.theme.darkBlueBackground : props.theme.lightGray};
    color: ${props => isSelected ? '#FFF' : props.theme.skyBlue};
    max-width: 50%;
    min-width: 150px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.darkBlueBackground};
      color: #FFF;
    }

    &:first-of-type {
      border-radius: 30px 0 0 30px;
    }

    &:last-of-type {
      border-radius: 0 30px 30px 0;
    }
  `;

  return (
    <Item {...props} onClick={() => handleSelectionChanged(index)} >
      {title}
    </Item>
  );
}
