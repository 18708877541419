import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { fetchFAQS, saveFAQ, removeFAQ } from '../../actions';
import FilterableTable from '../table';
import Portal from '../modal/Portal';
import { Loading, FestivalHeader } from '../controls';
import { FAQForm } from './FAQForm';

class FAQComponent extends React.Component {
  state = {
    showModal: false,
    selectedFAQ: {},
  }

  componentDidMount() {
    this.getFAQS();
  }

  getFAQS = () => {
    const { festival, actions } = this.props;
    actions.fetchFAQS(festival.festivalId);
  }

  buildColumnDefinitions = () => {
    var columnDefs = {};

    columnDefs.faqName = {
      header: 'FAQ',
      filterable: true,
      sortable: true,
      showViewLink: false,
      handleClick: true
    };

    return columnDefs;
  }

  openModal = (faq) => {
    const { faqs } = this.props;
    const selectedFAQ = faqs.find(f => faq.faqId === f.faqId) || {};
    this.setState({ selectedFAQ, showModal: true });
  }

  onFileUpload = (fileInfo) => {
    const { selectedFAQ } = this.state;
    const { filename, url } = fileInfo;
    this.setState({ selectedFAQ: { ...selectedFAQ, photoURL: url, faqPhoto: filename } });
  }

  onFileDelete = () => {
    const { selectedFAQ } = this.state;

    selectedFAQ.photoURL = null;
    selectedFAQ.faqPhoto = null;

    this.setState({ selectedFAQ });
  }

  saveFAQ = (values) => {
    const { festival, actions: { saveFAQ } } = this.props;
    const { selectedFAQ } = this.state;
    const updatedFAQ = { ...selectedFAQ, ...values };

    saveFAQ(updatedFAQ, festival.festivalId)
      .then(() => {
        this.closeModal();
        this.getFAQS();
        NotificationManager.success('FAQ saved', '', 3000);
      })
      .catch(() => {
        NotificationManager.error('Failed to save FAQ', 'Error', 3000);
      });
  }

  removeFAQ = () => {
    const { selectedFAQ } = this.state;
    const { actions } = this.props;
    actions.removeFAQ(selectedFAQ.faqId)
      .then(() => {
        NotificationManager.success('FAQ removed', '', 3000);
        this.setState({ showModal: false, selectedFAQ: {} });
        this.getFAQS();
      })
      .catch(() => {
        NotificationManager.error('FAQ could not be removed', '', 3000);
      });
  }

  closeModal = () => this.setState({ showModal: false, selectedFAQ: {} });

  render() {
    const { faqs, loading } = this.props;
    const { selectedFAQ, showModal } = this.state;

    return (
      <>
        <FestivalHeader
          title="FAQs"
          showExit={true}
          showAdd={true}
          addTitle="Add FAQ"
          addAction={this.openModal}
        />
        {loading ? (
          <Loading />
        ) : (
          <FilterableTable
            data={faqs}
            tableTitle=''
            columns={this.buildColumnDefinitions()}
            includeFilter={false}
            addNewRecordText='FAQ'
            editHandler={this.openModal}
            toggleModal={this.openModal}
          />
        )}

        <Portal
          header={selectedFAQ ? selectedFAQ.faqName || 'Add New FAQ' : 'Add New FAQ'}
          open={showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <FAQForm
            faq={selectedFAQ}
            handleSave={this.saveFAQ}
            loading={loading}
            handleRemove={this.removeFAQ}
            onFileDelete={this.onFileDelete}
            onFileUpload={this.onFileUpload}
          />
        </Portal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  faqs: state.faqStore.faqs,
  festival: state.festivalStore.festival,
  loading: state.faqStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchFAQS,
    saveFAQ,
    removeFAQ,
  }, dispatch)
});

export const FAQS = connect(mapStateToProps, mapDispatchToProps)(FAQComponent);
