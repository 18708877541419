import axiosClient from './axios-client';

export const fetchFestivals = (type = 'all') => ({
    type: 'FETCH_FESTIVALS',
    payload: axiosClient.get(`/admin/festivals/${type}`)
});

export const fetchFestival = (festivalId) => ({
    type: 'FETCH_FESTIVAL',
    payload: axiosClient.get(`/admin/festival/${festivalId}`)
});

export const saveFestival = (festival) => ({
    type: 'SAVE_FESTIVAL',
    payload: axiosClient.post('/admin/festival', festival)
});

export const setFestival = (festival) => ({
    type: 'SELECT_FESTIVAL',
    data: festival
});

export const updateFestival = (festival) => ({
  type: 'UPDATE_FESTIVAL',
  data: festival
});
