import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={17}
    defaultCenter={{ lat: props.lat, lng: props.lng }}
    onClick={props.handleClick}
  >
    {(props.lat && props.lng) && <Marker position={{ lat: props.lat, lng: props.lng }} />}
  </GoogleMap>
));

class MapClass extends React.Component {
  handleClick = (e) => {
    const { handleClick, location } = this.props;
    const { latLng } = e;
    handleClick({ ...location, latitude: latLng.lat(), longitude: latLng.lng() });
  }

  render() {
    const { location } = this.props;
    const lat = location ? location.latitude : 43.082857;
    const lng = location ? location.longitude : -89.3676184;

    return (
      <MapComponent
        lat={lat}
        lng={lng}
        handleClick={this.handleClick}
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyArB2vYYlozM4134K1-vXFDw-8swpeE_IY&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px`, width: '100%' }} />}
        mapElement={<div style={{ height: `100%`, borderRadius: '20px' }} />}
      />
    )
  }
}

export const Map = MapClass;
