import React from 'react';
import styled from 'styled-components';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormRow, FileUpload } from '../controls';
import { Select } from 'antd';

const Option = Select.Option;

const InfoWrapper = styled.div`
  display: flex;
`;

const LeftWrapper = styled.div`
  padding: 1rem;
  width: 75%;
`;

const RightWrapper = styled.div`
  padding: 1rem;
  width: 25%;
`;

const StyledFileUpload = styled(FileUpload)`
  width: 100%;
`;

const VendorSchema = Yup.object().shape({
  vendorName: Yup.string().required('Vendor name is required'),
  category: Yup.string().required('Category is required')
});

class VendorFormComponent extends React.Component {
  saveVendor = (values) => {
    const { handleSave, vendor: { vendorId } } = this.props;
    const category = values.category ? values.category : '';
    const subcategory = values.subcategory ? values.subcategory : '';
    const vendor = { ...values, category, subcategory, vendorId };

    handleSave(vendor);
  }

  render() {
    const {  vendor, vendorCategories, onFileUpload, onFileDelete } = this.props;
    const defaultLinks = {
      instagram: '',
      twitter: '',
      facebook: '',
      web: '',
    };

    const defaultCategory = vendor ? [vendor.category] : null;
    const defaultSubcategory = vendor ? [vendor.subcategory] : null;
    const defaultValues = {
      vendorName: vendor ? vendor.vendorName || '' : '',
      vendorContact: vendor ? vendor.vendorContact || '' : '',
      vendorDescription: vendor ? vendor.vendorDescription || '' : '',
      links: vendor ? vendor.links || defaultLinks : defaultLinks,
    };

    const categories = vendorCategories.categories || [];
    const subcategories = vendorCategories.subcategories || [];

    // Only adding categories to default values if they exist to prevent empty bubbles in the select field if empty
    if (defaultCategory) {
      defaultValues.category = defaultCategory[0];
    } else {
      defaultValues.category = null;
    }

    if (defaultSubcategory) {
      defaultValues.subcategory = defaultSubcategory[0];
    }

    var fileList = [];

    if (vendor && vendor.photoURL) {
      fileList.push({
        uid: '-1',
        name: vendor.vendorPhoto,
        url: vendor.photoURL,
        status: 'done'
      });
    }

    return (
      <Formik
        ref={node => this.form = node}
        enableReinitialize
        initialValues={defaultValues}
        onSubmit={this.saveVendor}
        validationSchema={VendorSchema}
      >
        {
          ({ errors, touched }) => (
            <Form>
              <InfoWrapper>
                <LeftWrapper>
                  <FormRow label="Vendor Name" errorName="vendorName">
                    <Field type="text" name="vendorName" placeholder="Name" />
                  </FormRow>
                  <FormRow label="Contact">
                    <Field type="text" name="vendorContact" placeholder="Contact" />
                  </FormRow>
                  <FormRow label="Category" errorName="category">
                    <Field
                      name="category"
                      render={({ field: { onChange, ...field }, form: { setFieldValue } }) => (
                        <Select
                          {...field}
                          placeholder="Select or enter a category"
                          mode="tags"
                          defaultValue={defaultCategory}
                          onChange={(newValue) => setFieldValue('category', newValue[newValue.length - 1])}
                        >
                          {categories.map(category =>
                            <Option key={category} value={category}>{category}</Option>
                          )}
                        </Select>
                      )}
                    />
                  </FormRow>
                  <FormRow label="Subcategory">
                    <Field
                      name="subcategory"
                      render={({ field: { onChange, ...field }, form: { setFieldValue } }) => (
                        <Select
                          {...field}
                          placeholder="Select or enter a subcategory"
                          mode="tags"
                          defaultValue={defaultSubcategory}
                          onChange={(newValue) => setFieldValue('subcategory', newValue[newValue.length - 1])}
                        >
                          {subcategories.map(subcategory =>
                            <Option key={subcategory} value={subcategory}>{subcategory}</Option>
                          )}
                        </Select>
                      )}
                    />
                  </FormRow>
                </LeftWrapper>
                <RightWrapper>
                  <FormRow>
                    <StyledFileUpload fileList={fileList} onFileUpload={onFileUpload} onFileDelete={onFileDelete} />
                  </FormRow>
                </RightWrapper>
              </InfoWrapper>
              <FormRow label="Description">
                <Field component="textarea" name="vendorDescription" placeholder="Vendor Description..." />
              </FormRow>
              <FormRow label={<FontAwesomeIcon icon={['fal', 'globe']} />}>
                <Field type="text" name="links.web" placeholder="Website" />
              </FormRow>
              <FormRow label={<FontAwesomeIcon icon={['fab', 'facebook']} />}>
                <Field type="text" name="links.facebook" placeholder="Facebook" />
              </FormRow>
              <FormRow label={<FontAwesomeIcon icon={['fab', 'twitter']} />}>
                <Field type="text" name="links.twitter" placeholder="Twitter" />
              </FormRow>
              <FormRow label={<FontAwesomeIcon icon={['fab', 'instagram']} />}>
                <Field type="text" name="links.instagram" placeholder="Instagram" />
              </FormRow>
              <Button style={{ float: 'right', marginTop: '1rem' }} mode="dark">Save Vendor</Button>
            </Form>
          )
        }
      </Formik>
    );
  }
}

export const VendorForm = VendorFormComponent;
