import React from 'react';
import styled from 'styled-components';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormButtons, FormRow, FileUpload } from '../controls';

const InfoWrapper = styled.div`
  display: flex;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 75%;
`;

const RightWrapper = styled.div`
  padding: 1rem;
  width: 25%;
`;

const StyledFileUpload = styled(FileUpload)`
  width: 100%;
`;

const SponsorSchema = Yup.object().shape({
  sponsorName: Yup.string().required('Sponsor name is required')
});

export const SponsorForm = ({ sponsor, onFileUpload, onFileDelete, handleSave, handleRemove, ...props }) => {
  const defaultValues = {
    sponsorName: sponsor.sponsorName || '',
    sponsorDescription: sponsor.sponsorDescription || '',
    links: sponsor.links || {
      instagram: '',
      twitter: '',
      facebook: '',
      web: '',
    }
  };

  var fileList = [];

  if (sponsor.photoURL) {
    fileList.push({
      uid: '-1',
      name: sponsor.sponsorPhoto,
      url: sponsor.photoURL,
      status: 'done'
    });
  }

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      onSubmit={handleSave}
      validationSchema={SponsorSchema}
    >
      {
        ({ errors, touched }) => (
          <Form>
            <InfoWrapper>
              <LeftWrapper>
                <FormRow label="Sponsor Name" errorName="sponsorName">
                  <Field type="text" name="sponsorName" placeholder="Name" />
                </FormRow>
              </LeftWrapper>
              <RightWrapper>
                <FormRow>
                  <StyledFileUpload fileList={fileList} onFileUpload={onFileUpload} onFileDelete={onFileDelete} />
                </FormRow>
              </RightWrapper>
            </InfoWrapper>
            <FormRow label="Description">
              <Field component="textarea" name="sponsorDescription" placeholder="Sponsor Description..." />
            </FormRow>
            <FormRow label={<FontAwesomeIcon icon={['fal', 'globe']} />}>
              <Field type="text" name="links.web" placeholder="Website" />
            </FormRow>
            <FormRow label={<FontAwesomeIcon icon={['fab', 'facebook']} />}>
              <Field type="text" name="links.facebook" placeholder="Facebook" />
            </FormRow>
            <FormRow label={<FontAwesomeIcon icon={['fab', 'twitter']} />}>
              <Field type="text" name="links.twitter" placeholder="Twitter" />
            </FormRow>
            <FormRow label={<FontAwesomeIcon icon={['fab', 'instagram']} />}>
              <Field type="text" name="links.instagram" placeholder="Instagram" />
            </FormRow>
            <FormButtons showDelete={sponsor.sponsorId} label="Sponsor" onDelete={handleRemove} />
          </Form>
        )
      }
    </Formik>
  );
}
