import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import apiMiddleware from './middleware/api-middleware';
import rootReducer from './reducers';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['festivalStore', 'authStore']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(thunk, apiMiddleware),
);
export const persistor = persistStore(store);