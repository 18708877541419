import { combineReducers } from 'redux';
import festivalStore from './festival-reducer';
import locationStore from './location-reducer';
import performerStore from './performer-reducer';
import stageStore from './stage-reducer';
import vendorStore from './vendor-reducer';
import serviceStore from './services-reducer';
import sponsorStore from './sponsor-reducer';
import faqStore from './faq-reducer';
import attractionStore from './attraction-reducer';
import authStore from './auth-reducer';

const reducers = {
  attractionStore,
  festivalStore,
  faqStore,
  locationStore,
  performerStore,
  serviceStore,
  sponsorStore,
  stageStore,
  vendorStore,
  authStore,  
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
}

export default rootReducer;

export const updateStateWithLoading = (state) => ({
  ...state,
  loading: true,
  error: false,
});

export const updateStateWithValue = (key, value, state) => ({
  ...state,
  loading: false,
  [key]: value
});

export const updateStateWithError = (error, state) => ({
  ...state,
  loading: false,
  error
});
