import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import MenuItem from './NavItem';

const NavMenu = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const MenuComponent = ({ festival, ...props }) => (
  <NavMenu>
    <MenuItem title="Festival Info" icon="info-circle" path={`festival/${festival ? festival.festivalId : 'new'}`} />
    <MenuItem title="Stages" icon="music" path="stages" />
    <MenuItem title="Attractions" icon="pennant" path="attractions" />
    <MenuItem title="Vendors" icon="id-card-alt" path="vendors" />
    <MenuItem title="Sponsors" icon="handshake" path="sponsors" />
    <MenuItem title="Services" icon="concierge-bell" path="services" />
    <MenuItem title="FAQ" icon="question-circle" path="faq" />
    <MenuItem title="Push Notification" icon="envelope" path="push-notification" />
  </NavMenu>
);

export default withRouter(MenuComponent);
