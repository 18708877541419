import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationManager } from 'react-notifications';
import { fetchVendors, fetchAllVendors, saveFestivalVendor, removeVendor } from '../../actions';
import { Loading, FestivalHeader } from '../controls';
import FilterableTable from '../table';
import Portal from '../modal/Portal';
import { FestivalVendorForm } from './';

class FestivalVendorsComponent extends React.Component {
  state = {
    selectedVendor: {},
    showModal: false,
  };

  componentDidMount() {
    this.getFestivalVendors();
  }

  getFestivalVendors = () => {
    const { festival: { festivalId }, actions } = this.props;
    actions.fetchVendors(festivalId);
    actions.fetchAllVendors();
  }

  buildColumnDefinitions = () => {
    var columnDefs = {};

    columnDefs.vendorName = {
      header: 'Vendor Name',
      filterable: true,
      sortable: true,
      showViewLink: false,
      handleClick: true
    };
    columnDefs.category = {
      header: 'Category',
      filterable: false,
      sortable: false
    };
    columnDefs.subcategory = {
      header: 'Subcategory',
      filterable: false,
      sortable: false
    };

    return columnDefs;
  }

  openModal = (vendor) => {
    const { festivalVendors } = this.props;
    const selectedVendor = festivalVendors.find(v => v.vendor.vendorId === vendor.vendorId) || {};
    this.setState({ selectedVendor, showModal: true });
  }

  onLocationChange = (location) => {
    const { selectedVendor } = this.state;
    const updatedLocation = { ...selectedVendor.location, ...location, radius: 20 };
    const updatedVendor = { ...selectedVendor, location: updatedLocation };
    this.setState({ selectedVendor: updatedVendor });
  }

  handleSave = (vendor) => {
    const { actions: { saveFestivalVendor }, festival } = this.props;
    const { selectedVendor } = this.state;
    const updatedVendor = { ...selectedVendor, ...vendor };

    saveFestivalVendor(updatedVendor, festival.festivalId)
      .then(() => {
        this.setState({ showModal: false, selectedVendor: {} });
        NotificationManager.success('Vendor saved', '', 3000);
        this.getFestivalVendors();
      })
      .catch(() => {
        NotificationManager.error('Vendor could not be saved', '', 3000)
      });
  }

  handleRemove = () => {
    const { actions, festival } = this.props;
    const { selectedVendor } = this.state;

    actions.removeVendor(selectedVendor.vendorId, festival.festivalId)
      .then(() => {
        NotificationManager.success('Vendor removed', '', 3000);
        this.setState({ showModal: false, selectedVendor: {} });
        this.getFestivalVendors();
      })
      .catch(() => {
        NotificationManager.error('Vendor could not be removed', '', 3000);
      });
  }

  render() {
    const { festivalVendors, vendors, loading } = this.props;
    const { selectedVendor, showModal } = this.state;
    const vendorListData = festivalVendors.map(vendor => vendor.vendor);

    return (
      <>
        <FestivalHeader
          title="Festival Vendors"
          showExit={true}
          showAdd={true}
          addTitle="Add Vendor"
          addAction={this.openModal}
        />
        {loading ? (
          <Loading />
        ) : (
          <FilterableTable
            data={vendorListData}
            tableTitle=''
            columns={this.buildColumnDefinitions()}
            includeFilter={false}
            editHandler={this.openModal}
            toggleModal={this.openModal}
          />
        )}

        <Portal
          header={selectedVendor.vendor ? selectedVendor.vendor.vendorName || 'Add Vendor' : 'Add Vendor'}
          open={showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <FestivalVendorForm
            vendor={selectedVendor}
            vendors={vendors}
            handleSave={this.handleSave}
            onLocationChange={this.onLocationChange}
            handleRemove={this.handleRemove}
          />
        </Portal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  vendors: state.vendorStore.vendors,
  festivalVendors: state.vendorStore.festivalVendors,
  festival: state.festivalStore.festival,
  loading: state.vendorStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchVendors,
    fetchAllVendors,
    saveFestivalVendor,
    removeVendor,
  }, dispatch)
});

export const FestivalVendors = connect(mapStateToProps, mapDispatchToProps)(FestivalVendorsComponent);
