import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { NotificationManager } from 'react-notifications';
import { FestivalHeader, Loading } from '../controls';
import { fetchStages, fetchPerformances, saveStage, savePerformance, removePerformance, fetchPerformers } from '../../actions';
import FilterableTable from '../table';
import Portal from '../modal/Portal';
import { StageFormContainer } from './';

const Wrapper = styled.div`
  padding: 2rem;
`;

class StageComponent extends React.Component {
  state = {
    showModal: false,
    selectedStage: {},
  };

  componentDidMount() {
    this.fetchStagesAndPerformances();
  }

  fetchStagesAndPerformances = () => {
    const { festival: { festivalId }, actions } = this.props;
    actions.fetchStages(festivalId);
    actions.fetchPerformances(festivalId);
    actions.fetchPerformers();
  }

  buildColumnDefinitions = () => {
    var columnDefs = {};
    columnDefs.stageName = {
      header: 'Stage',
      filterable: true,
      sortable: true,
      showViewLink: false,
      handleClick: true
    };
    columnDefs.performers = {
      header: 'Performers',
      filterable: false,
      sortable: false
    };
    return columnDefs;
  }

  onFileUpload = (fileInfo) => {
    const { selectedStage } = this.state;
    const { url, filename } = fileInfo;
    this.setState({ selectedStage: { ...selectedStage, photoURL: url, stagePhoto: filename } });
  }

  onFileDelete = () => {
    const { selectedStage } = this.state;
    this.setState({ selectedStage: { ...selectedStage, photoURL: null, stagePhoto: null } });
  }

  openStageModal = (stage) => {
    const { stages } = this.props;
    const selectedStage = stages.find(s => s.stageId === stage.stageId) ? stage : {};
    this.setState({ selectedStage, showModal: true });
  }

  // Update the date on the selected stage
  handleDateChange = (dates) => {
    const { selectedStage } = this.state;
    this.setState({ selectedStage: { ...selectedStage, times: dates }})
  }

  // Update the location on the selected stage
  handleLocationChange = (location) => {
    const { selectedStage } = this.state;
    const updatedLocation = { ...selectedStage.location, ...location, radius: 50 };
    console.log(updatedLocation);
    this.setState({selectedStage: { ...selectedStage, location: updatedLocation } });
  }

  // Saves the stage to the API
  handleStageSave = (stage) => {
    const { festival: { festivalId }, actions } = this.props;
    const { selectedStage } = this.state;

    delete selectedStage.performances;
    delete selectedStage.performers;

    const updatedStage = { ...selectedStage, ...stage };

    actions.saveStage(festivalId, updatedStage)
      .then(() => {
        actions.fetchStages(festivalId)
        NotificationManager.success('Stage saved', '', 3000);
        this.setState({ showModal: false, selectedStage: {} });
      })
      .catch(() => {
        NotificationManager.error('Stage could not be saved', '', 3000);
      })
  }

  handlePerformanceSave = (performance) => {
    const { festival: { festivalId }, actions } = this.props;
    const savePromise = new Promise((resolve, reject) =>{
      actions.savePerformance(performance, festivalId)
        .then(() => {
          NotificationManager.success('Performance saved', '', 3000);
          this.fetchStagesAndPerformances();
          resolve();
        })
        .catch(() => {
          NotificationManager.error('Performance could not be saved', '', 3000);
          reject();
        });
    });

    return savePromise;
  }

  handlePerformanceDelete = (performance) => {
    const { actions } = this.props;

    return new Promise((resolve, reject) => {
      actions.removePerformance(performance.performanceId)
        .then(() => {
          NotificationManager.success('Performance removed', '', 3000);
          this.fetchStagesAndPerformances();
          resolve();
        })
        .catch(() => {
          NotificationManager.error('Performance could not be removed', '', 3000);
          reject();
        });
    });
  }

  getPerformerList = (performances) => {
    if (!performances) {
      return '';
    }

    let performers = performances
      .reduce((list, performance) => list + `${performance.performer.performerName}, `, '')
      .trim()
      .slice(0, -1);

    return performers;
  }

  buildViewLink = (stage) => `/stages/${stage.stageId}`;

  render() {
    const { stages, performances, stagesLoading, performersLoading, performers } = this.props;
    const { showModal, selectedStage } = this.state;
    const dictOfPerformers = performances.reduce((dict, performance) => {
      if (!dict[performance.stageId]) {
        dict[performance.stageId] = [performance]
      } else {
        dict[performance.stageId].push(performance)
      }

      return dict;
    }, {});

    const populatedStages = stages.map(stage => ({
      ...stage,
      performances: dictOfPerformers[stage.stageId],
      performers: this.getPerformerList(dictOfPerformers[stage.stageId])
    }));

    return (
      <>
        <FestivalHeader
          title="Stages"
          showExit={true}
          showAdd={true}
          addTitle="Add Stage"
          addAction={this.openStageModal}
        />
        <Wrapper>
          {(stagesLoading || performersLoading) ?
            <Loading /> :
            <FilterableTable
              data={populatedStages}
              tableTitle=''
              columns={this.buildColumnDefinitions()}
              includeFilter={false}
              viewLinkBuilder={this.buildViewLink}
              editHandler={this.openStageModal}
              toggleModal={() => this.setState({ showModal: true, selectedStage: {} })}
            />
          }
        </Wrapper>

        <Portal
          header={selectedStage ? selectedStage.stageName || 'Add New Stage' : 'Add New Stage'}
          open={showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <StageFormContainer
            stage={selectedStage}
            onStageSave={this.handleStageSave}
            onPerformanceSave={this.handlePerformanceSave}
            onPerformanceDelete={this.handlePerformanceDelete}
            onDateChange={this.handleDateChange}
            onLocationChange={this.handleLocationChange}
            performers={performers}
            onFileUpload={this.onFileUpload}
            onFileDelete={this.onFileDelete}
          />
        </Portal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  festival: state.festivalStore.festival,
  stages: state.stageStore.stages,
  performances: state.performerStore.performances,
  stagesLoading: state.stageStore.loading,
  performersLoading: state.performerStore.loading,
  performers: state.performerStore.performers,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchStages,
    fetchPerformances,
    saveStage,
    savePerformance,
    removePerformance,
    fetchPerformers,
  }, dispatch)
});

export const Stages =  connect(mapStateToProps, mapDispatchToProps)(StageComponent);
