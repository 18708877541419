import React from 'react';
import { css } from '@emotion/core';
import { PropagateLoader } from 'react-spinners';

const style = css`
  position: absolute!important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  height: 25px;
  width: 200px;
`;

export const Loading = () => (
  <div style={{ width: '100%', position: 'relative', height: '100px' }}>
    <PropagateLoader
      css={style}
      sizeUnit={"px"}
      size={15}
      color={'#38abd8'}
      loading={true}
    />
  </div>
);
