import React, { Component } from 'react';
import HeaderComponent from './components/header'
import MainContainer from './components/main-container';
import { withRouter } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import './App.css';
import 'react-notifications/lib/notifications.css';

class App extends Component {
  render() {
    var url = window.location.pathname;
    var urlPath = url.split("/").slice(-1)[0];
    var withSideNav = false;
    var withHeader = false;

    switch (urlPath) {
      case 'login':
          withSideNav = false;
          withHeader = false;
          break;
      case '':
        withSideNav = false;
        withHeader = true;
        break;

      default:
        withSideNav = true;
        withHeader = true;
    }

    return (
      <>
        <HeaderComponent withHeader={withHeader}/>
        <MainContainer withSideNav={withSideNav} />
        <NotificationContainer />
      </>
    );
  }
}

export default withRouter(App);
