import { updateStateWithLoading, updateStateWithValue, updateStateWithError } from './';

const initialState = {
  services: [],
  service: {},
  serviceTypes: [],
  loading: false,
  error: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_SERVICES_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_SERVICES_FULFILLED':
      return updateStateWithValue('services', action.data, state);

    case 'FETCH_SERVICES_REJECTED':
      return updateStateWithError(action.error, state);

    case 'FETCH_SERVICE_TYPES_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_SERVICE_TYPES_FULFILLED':
      return updateStateWithValue('serviceTypes', action.data, state);

    case 'FETCH_SERVICE_TYPES_REJECTED':
      return updateStateWithError(action.error, state);

    case 'SAVE_SERVICE_PENDING':
      return updateStateWithLoading(state);

    case 'SAVE_SERVICE_FULFILLED':
      return updateStateWithValue('service', action.data, state);

    case 'SAVE_SERVICE_REJECTED':
      return updateStateWithError(action.error, state);

    case 'REMOVE_SERVICE_PENDING':
      return updateStateWithLoading(state);

    case 'REMOVE_SERVICE_FULFILLED':
      return updateStateWithValue('service', {}, state);

    case 'REMOVE_SERVICE_REJECTED':
      return updateStateWithError(action.error, state);

    default:
      return state;
  }
}
