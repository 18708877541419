import { updateStateWithLoading, updateStateWithValue, updateStateWithError } from './';

const initialState = {
  festivals: [],
  festival: {},
  loading: false,
  error: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_FESTIVALS_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_FESTIVALS_FULFILLED':
      return updateStateWithValue('festivals', action.data, state);

    case 'FETCH_FESTIVALS_REJECTED':
      return updateStateWithError(action.error, state);

    case 'FETCH_FESTIVAL_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_FESTIVAL_FULFILLED':
      return updateStateWithValue('festival', action.data, state);

    case 'FETCH_FESTIVAL_REJECTED':
      return updateStateWithError(action.error, state);

    case 'SELECT_FESTIVAL':
      return updateStateWithValue('festival', action.data, state);

    case 'UPDATE_FESTIVAL':
      return { ...state, festival: action.data };

    default:
      return state;
  }
}
