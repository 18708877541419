import React from 'react';
import { Form, Formik, Field } from 'formik';
import { Select } from 'antd';
import * as Yup from 'yup';
import { FormButtons, Error, FormRow, DateFormWidget } from '../controls';

const Option = Select.Option;

const PerformanceSchema = Yup.object({
  performer: Yup.object().shape({
    performerName: Yup.string().min(1).required('Performer is required'),
  }).required('Performer is required')
})

class PerformanceFormComponent extends React.Component {
  render() {
    const { performance, handleSave, handleDateChange, performers, performerUpdated, handleRemove } = this.props;
    const defaultDateObject = {
      startTime: performance.startTime || null,
      endTime: performance.endTime || null,
      startTimeMS: performance.startTimeMS || null,
      endTimeMS: performance.endTimeMS || null
    };
    const dates = [defaultDateObject];
    const defaultValues = {
      performanceNotes: performance.performanceNotes || '',
      performer: performance.performer || {},
      performerId: performance.performer ? performance.performer.performerId || 0 : 0,
      dates
    };

    return (
      <Formik
        enableReinitialize
        initialValues={defaultValues}
        onSubmit={handleSave}
        validationSchema={PerformanceSchema}
      >
        {
          ({ errors, touched }) => (
            <Form>
              <FormRow label="Performer">
                <Field
                  name="performer.performerName"
                  render={({ field: { onChange, ...field }, form: { setFieldValue } }) => (
                    <Select
                      {...field}
                      showSearch
                      filterOption={true}
                      placeholder="Select a performer"
                      defaultValue={performance.performer ? performance.performer.performerName || '' : ''}
                      onChange={
                        (newValue) => {
                          const performer = performers.find(performer => performer.performerId.toString() === newValue);
                          setFieldValue('performer', performer);
                          setFieldValue('performerId', performer.performerId);
                          performerUpdated(performer);
                        }
                      }
                    >
                      {performers.map(performer =>
                        <Option key={performer.performerId} value={performer.performerId.toString()}>{performer.performerName}</Option>
                      )}
                    </Select>
                  )}
                />
              {errors.performer &&
                <Error>{errors.performer.performerName}</Error>
              }
              </FormRow>
              <DateFormWidget label="Date & Times" dates={dates} handleDateChange={handleDateChange} required />
              <FormRow label="Notes">
                <Field component="textarea" name="performanceNotes" placeholder="Performance Notes..." />
              </FormRow>
              <FormButtons showDelete={performance.performanceId} label="Performance" onDelete={handleRemove} />
            </Form>
          )
        }
      </Formik>
    );
  }
}

export const PerformanceForm = PerformanceFormComponent;
