import { updateStateWithLoading, updateStateWithValue, updateStateWithError } from './';

const initialState = {
  loading: false,
  error: false,
  vendors: [],
  festivalVendors: [],
  vendor: {},
  vendorCategories: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_VENDORS_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_VENDORS_FULFILLED':
      return updateStateWithValue('festivalVendors', action.data, state);

    case 'FETCH_VENDORS_REJECTED':
      return updateStateWithError(action.error, state);

    case 'FETCH_ALL_VENDORS_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_ALL_VENDORS_FULFILLED':
      return updateStateWithValue('vendors', action.data, state);

    case 'FETCH_ALL_VENDORS_REJECTED':
      return updateStateWithError(action.error, state);

    case 'SAVE_VENDOR_PENDING':
      return updateStateWithLoading(state);

    case 'SAVE_VENDOR_FULFILLED':
      return updateStateWithValue('vendor', action.data, state);

    case 'SAVE_VENDOR_REJECTED':
      return updateStateWithError(action.error, state);

    case 'REMOVE_VENDOR_PENDING':
      return updateStateWithLoading(state);

    // TODO: handle removed action better...
    case 'REMOVE_VENDOR_FULFILLED':
      return updateStateWithValue('vendors', state.vendors, state);

    case 'REMOVE_VENDOR_REJECTED':
      return updateStateWithError(action.error, state);

    case 'FETCH_VENDOR_CATEGORIES_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_VENDOR_CATEGORIES_FULFILLED':
      return updateStateWithValue('vendorCategories', action.data, state);

    case 'FETCH_VENDOR_CATEGORIES_REJECTED':
      return updateStateWithError(action.error, state);

    default:
      return state;
  }
}
