import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Poppins:400,600');

  body {
    font-family: 'Poppins', sans-serif;
    padding: 0;
    margin: 0;
  }

  button { outline: none; }

  a {
    text-decoration: none;

    &:visited {
      color: inherit;
    }
  }

  input[type='text'],
  input[type='password'],
  textarea {
    height: 40px;
    border: 1px solid #979797;
    border-radius: 20px;
    padding: 0 1rem;
    outline: none;
    box-shadow: none;

    &::placeholder {
      font-style: italic;
      color: #9b9b9b;
    }
  }

  textarea {
    min-height: 40px;
    height: 250px;
    line-height: 1.3rem!important;
    padding: 1rem;
  }

  .ant-modal-wrap  {
    z-index: 2000!important;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 100%;
    height: 100%;
  }

  .ant-select-selection {
    height: 40px!important;
    border-color: #979797!important;
    border-radius: 20px!important;
  }

  .ant-select-selection__rendered {
    height: 40px!import;
    line-height: 40px!important;

    ul > li {
      border-radius: 20px!important;
      height: 30px!important;
      line-height: 30px!important;
      margin-top: 4px!important;
    }
  }

  .ant-upload-list-item {
    width: 150px!important;
    height: auto!important;
  }
`

export default GlobalStyle;
