import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => {    
  if (isAuthenticated) {    
    return <Route {...rest} render={(props) => (
      <Component {...props} />
    )} />
  } else {
    return <Route {...rest} render={(props) => (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
    )} />
  }
};

const mapStateToProps = (state, ownProps) => ({  
  isAuthenticated: state.authStore.isAuthenticated
});

export default withRouter(
  connect(mapStateToProps)(ProtectedRoute)
);
