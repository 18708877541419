import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { NotificationManager } from 'react-notifications';
import { Select, List, Avatar } from 'antd';
import { fetchSponsorsForObject, associateSponsor, disassociateSponsor, fetchSponsors } from '../../actions';
import { Button } from '../controls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Option = Select.Option;

const StyledSelect = styled(Select)`
  width: 75%;
`;

const SelectWrapper = styled.div`
  margin: 1rem 0;
  width: 100%;
  text-align: center;
`;

const Wrapper = styled.div`
  padding: 1.5rem;
`;

class AssignedSponsorsComponent extends React.Component {
  componentDidMount() {
    this.fetchSponsors();
  }

  fetchSponsors = () => {
    const { objectType, objectId, actions, festival: { festivalId } } = this.props;
    actions.fetchSponsorsForObject(festivalId, objectType, objectId);
    actions.fetchSponsors(festivalId);
  }

  handleSponsorChange = (value) => {
    console.log(value);
    const { festival, objectType, objectId, actions: { associateSponsor } } = this.props;
    associateSponsor(festival.festivalId, value[0], objectType, objectId)
      .then(() => {
        this.fetchSponsors();
        NotificationManager.success('Sponsor added', '', 3000);
      })
      .catch(() => {
        NotificationManager.error('Sponsor could not be added', '', 3000);
      });
  }

  handleRowRemove = (sponsorId) => {
    const { festival, objectType, objectId, actions: { disassociateSponsor } } = this.props;
    disassociateSponsor(festival.festivalId, sponsorId, objectType, objectId)
      .then(() => {
        this.fetchSponsors();
        NotificationManager.success('Sponsor removed', '', 3000);
      })
      .catch(() => {
        NotificationManager.error('Sponsor could not be removed', '', 3000);
      });
  }

  render() {
    const { sponsors, objectSponsors, loading } = this.props;
    var filteredSponsors = sponsors || [];

    if (sponsors && objectSponsors) {
      filteredSponsors = sponsors.filter(s => objectSponsors.find(sp => sp.sponsorId === s.sponsorId) == null );
    }

    return (
      <Wrapper>
        <SelectWrapper>
          <StyledSelect
            mode="tags"
            defaultValue={[]}
            value={[]}
            onChange={this.handleSponsorChange}
            placeholder="Select sponsors"
            notFoundContent="No sponsors left"
            loading={loading}
          >
            {filteredSponsors.map(sponsor =>
              <Option key={sponsor.sponsorId} value={sponsor.sponsorId.toString()}>{sponsor.sponsorName}</Option>
            )}
          </StyledSelect>
        </SelectWrapper>
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={objectSponsors}
          renderItem={item => (
            <List.Item
              actions={[
                <Button onClick={() => this.handleRowRemove(item.sponsorId)}>
                  <FontAwesomeIcon icon={['far', 'trash']} />
                </Button>
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar src={item.photoURL} />}
                title={item.sponsorName}
                description={`${item.sponsorDescription.substring(0, 200)}...`}
              />
            </List.Item>
          )}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.sponsorStore.loading,
  festival: state.festivalStore.festival,
  sponsors: state.sponsorStore.sponsors,
  objectSponsors: state.sponsorStore.objectSponsors,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchSponsors,
    fetchSponsorsForObject,
    associateSponsor,
    disassociateSponsor,
  }, dispatch)
});

export const AssignedSponsors = connect(mapStateToProps, mapDispatchToProps)(AssignedSponsorsComponent);
