import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1003;
`;

const Dialog = styled.div`
  border-radius: 20px;
  background-color: #FFF;
  position: fixed;
  top: 2%;
  right: 20%;
  bottom: auto;
  left: 20%;
  z-index: 1004;
  padding-bottom: 1rem;
  overflow-y: scroll;
  max-height: calc(90%);
  max-width: 1024px;
  margin: 0 auto;

  > header {
    align-items: center;
    display: flex;
    font-size: 1.25rem;
    font-weight: 700;
    justify-content: space-between;
    padding: 0.5rem 1rem 0.5rem 2rem;

    > h3 {
      display: flex;
      justify-content: space-between;
      height: 60px;
      line-height: 60px;
      border-bottom: 5px solid ${props => props.theme.lightGray};
      width: 100%;
      color: ${props => props.theme.lightTextColor};

      > button {
        border: none;
        padding: 0;
        font-size: inherit;
        background: inherit;
        color: $primary-color;
        cursor: pointer;
        margin: 0;
        transition: all 0.2s ease;

        &:hover{
          color: $dusk;
        }
      }
    }
  }
`;

const ModalContent = styled.div`
  padding: 1rem 2rem;
`;

const CloseButton = styled.button`
  color: ${props => props.theme.skyBlue};
`;

class Modal extends Component {
  render() {
    return this.props.open ? (
      <div>
        <Background onClick={() => this.props.onClose()} />
        <Dialog role="dialog">
          <header>
            <h3>
              {this.props.header}
              <CloseButton
                onClick={() => this.props.onClose()}
                type="button"
                aria-label="Close modal"
              >
                <FontAwesomeIcon icon={['fal', 'times-circle']} />
              </CloseButton>
            </h3>
          </header>
          <ModalContent>{this.props.children}</ModalContent>
        </Dialog>
      </div>
    ) : null
  }
}

export default Modal;
