import { updateStateWithLoading, updateStateWithValue, updateStateWithError } from './';

const initialState = {
  loading: false,
  error: false,
  stages: [],
  stage: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_STAGES_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_STAGES_FULFILLED':
      return updateStateWithValue('stages', action.data, state);

    case 'FETCH_STAGES_REJECTED':
      return updateStateWithError(action.error);

    case 'SAVE_STAGE_PENDING':
      return updateStateWithLoading(state);

    case 'SAVE_STAGE_FULFILLED':
      return updateStateWithValue('stage', action.data, state);

    case 'SAVE_STAGE_REJECTED':
      return updateStateWithError(action.error);

    case 'STAGE_UPDATED':
      return updateStateWithValue('stage', action.data, state);

    default:
      return state;
  }
}
