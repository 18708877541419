import { updateStateWithLoading, updateStateWithValue, updateStateWithError } from './';

const initialState = {
  attractions: [],
  attraction: {},
  loading: false,
  error: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_PENDING':
    case 'SAVE_PENDING':
    case 'REMOVE_PENDING':
      return updateStateWithLoading(state);

    case 'FETCH_FULFILLED':
    case 'SAVE_FULFILLED':
      return updateStateWithValue(action.property, action.data, state);

    case 'REMOVE_FULFILLED':
      return updateStateWithValue(action.property, {}, state);

    case 'FETCH_REJECTED':
    case 'SAVE_REJECTED':
    case 'REMOVE_REJECTED':
      return updateStateWithError(action.error, state);

    default:
      return state;
  }
}
